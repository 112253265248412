import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const FeatureAlert = () => {
  const [showAnnouncement, setShowAnnouncement] = useState(false);
  const { user } = useAuth0();
  
  // You can update this version number whenever you deploy new features
  const currentVersion = '1.1.0';
  
  useEffect(() => {
    const lastSeenVersion = localStorage.getItem(`lastSeenVersion_${user?.sub}`);
    if (lastSeenVersion !== currentVersion) {
      setShowAnnouncement(true);
    }
  }, [user]);

  const handleClose = () => {
    localStorage.setItem(`lastSeenVersion_${user?.sub}`, currentVersion);
    setShowAnnouncement(false);
  };

  if (!showAnnouncement) return null;

  return (
    <div className="fixed top-4 right-4 max-w-xs bg-white dark:bg-secondary-dark rounded-lg shadow-lg border border-border dark:border-border-dark p-4 z-50">
      <div className="flex justify-between items-start">
        <div className="flex-1">
          <h3 className="text-lg font-semibold text-text dark:text-text-dark">
            Two big udpates 🎉
          </h3>
          <div className="mt-2 text-text/90 dark:text-text-dark/90">
            <p>🌐 We've removed the waitlist and opened up access to everyone. Everyone who joins will get a 14-day free trial.</p>
            <p>🌳 In addition, we're excited to announce that we will invest 10% of all revenue into carbon removal projects.</p>
            <p>We hope you enjoy using Elly want to spread the word to the people you care about</p>
          </div>
        </div>
        <button
          onClick={handleClose}
          className="ml-4 text-gray-400 hover:text-gray-500 dark:text-gray-500 dark:hover:text-gray-400"
        >
          <span className="sr-only">Close</span>
          <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default FeatureAlert;