import React from 'react';

export default function TermsOfUse() {
    return (
        <div className="pt-24 pb-12 px-6 max-w-4xl mx-auto bg-white dark:bg-secondary-dark">
            <h1 className="text-3xl font-bold text-gray-900 dark:text-gray-100 mb-4">
                TERMS OF USE
            </h1>
            <p className="text-sm text-gray-600 dark:text-gray-400 mb-8">
                Last updated November 23, 2024
            </p>
            
            <div className="prose dark:prose-invert max-w-none">
                <section className="mb-8">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">Agreement to Our Legal Terms</h2>
                    <p className="text-gray-600 dark:text-gray-400">
                        We are Elly ApS, doing business as Elly ('Company', 'we', 'us', or 'our'), 
                        a company registered in Denmark at Porcelænshaven 4D, 1. tv, Frederiksberg 2000.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400 mt-4">
                        We operate the website <a href="https://ask-elly.co" className="text-text dark:text-text-dark hover:underline" target="_blank" rel="noopener noreferrer">ask-elly.co</a> (the 'Site'), 
                        as well as any other related products and services that refer or link to these legal terms (the 'Legal Terms') 
                        (collectively, the 'Services').
                    </p>
                    <p className="text-gray-600 dark:text-gray-400 mt-4">
                        You can contact us by email at <a href="mailto:simon@ask-elly.co" className="text-text dark:text-text-dark hover:underline">simon@ask-elly.co</a> or 
                        by mail to Porcelænshaven 4D, 1. tv, Frederiksberg 2000, Denmark.
                    </p>
                </section>

                <section className="mb-8">
                    <p className="text-gray-600 dark:text-gray-400">
                        These Legal Terms constitute a legally binding agreement made between you, whether personally or on behalf 
                        of an entity ('you'), and Elly ApS, concerning your access to and use of the Services. You agree that by 
                        accessing the Services, you have read, understood, and agreed to be bound by all of these Legal Terms. 
                        IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE 
                        SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.
                    </p>
                </section>
                <section className="mb-8">
                    <p className="text-gray-600 dark:text-gray-400">
                        We will provide you with prior notice of any scheduled changes to the Services you are using. The modified Legal Terms will become effective upon posting or notifying you 
                        by email. By continuing to use the Services after the effective date of any changes, you agree to be bound by the modified terms.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400 mt-4">
                        The Services are intended for users who are at least 18 years old. Persons under the age of 18 are not permitted 
                        to use or register for the Services.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400 mt-4">
                        We recommend that you print a copy of these Legal Terms for your records.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">Table of Contents</h2>
                    <ol className="list-decimal text-gray-600 dark:text-gray-400 space-y-2">
                        <li><a href="#services" className="text-text dark:text-text-dark hover:underline">Our Services</a></li>
                        <li><a href="#ip" className="text-text dark:text-text-dark hover:underline">Intellectual Property Rights</a></li>
                        <li><a href="#userreps" className="text-text dark:text-text-dark hover:underline">User Representations</a></li>
                        <li><a href="#userreg" className="text-text dark:text-text-dark hover:underline">User Registration</a></li>
                        <li><a href="#purchases" className="text-text dark:text-text-dark hover:underline">Purchases and Payment</a></li>
                        <li><a href="#subscriptions" className="text-text dark:text-text-dark hover:underline">Subscriptions</a></li>
                        <li><a href="#prohibited" className="text-text dark:text-text-dark hover:underline">Prohibited Activities</a></li>
                        <li><a href="#ugc" className="text-text dark:text-text-dark hover:underline">User Generated Contributions</a></li>
                        <li><a href="#license" className="text-text dark:text-text-dark hover:underline">Contribution License</a></li>
                        <li><a href="#reviews" className="text-text dark:text-text-dark hover:underline">Guidelines for Reviews</a></li>
                        <li><a href="#socialmedia" className="text-text dark:text-text-dark hover:underline">Social Media</a></li>
                        <li><a href="#thirdparty" className="text-text dark:text-text-dark hover:underline">Third-Party Websites and Content</a></li>
                        <li><a href="#sitemanage" className="text-text dark:text-text-dark hover:underline">Services Management</a></li>
                        <li><a href="#ppyes" className="text-text dark:text-text-dark hover:underline">Privacy Policy</a></li>
                        <li><a href="#copyrightyes" className="text-text dark:text-text-dark hover:underline">Copyright Infringements</a></li>
                        <li><a href="#terms" className="text-text dark:text-text-dark hover:underline">Term and Termination</a></li>
                        <li><a href="#modifications" className="text-text dark:text-text-dark hover:underline">Modifications and Interruptions</a></li>
                        <li><a href="#law" className="text-text dark:text-text-dark hover:underline">Governing Law</a></li>
                        <li><a href="#disputes" className="text-text dark:text-text-dark hover:underline">Dispute Resolution</a></li>
                        <li><a href="#corrections" className="text-text dark:text-text-dark hover:underline">Corrections</a></li>
                        <li><a href="#disclaimer" className="text-text dark:text-text-dark hover:underline">Disclaimer</a></li>
                        <li><a href="#liability" className="text-text dark:text-text-dark hover:underline">Limitations of Liability</a></li>
                        <li><a href="#indemnification" className="text-text dark:text-text-dark hover:underline">Indemnification</a></li>
                        <li><a href="#userdata" className="text-text dark:text-text-dark hover:underline">User Data</a></li>
                        <li><a href="#electronic" className="text-text dark:text-text-dark hover:underline">Electronic Communications, Transactions, and Signatures</a></li>
                        <li><a href="#california" className="text-text dark:text-text-dark hover:underline">California Users and Residents</a></li>
                        <li><a href="#misc" className="text-text dark:text-text-dark hover:underline">Miscellaneous</a></li>
                        <li><a href="#contact" className="text-text dark:text-text-dark hover:underline">Contact Us</a></li>
                    </ol>
                </section>

                <section className="mb-8" id="services">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">1. Our Services</h2>
                    <p className="text-gray-600 dark:text-gray-400">
                        The information provided when using the Services is not intended for distribution to or use by any person or entity 
                        in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would 
                        subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose 
                        to access the Services from other locations do so on their own initiative and are solely responsible for compliance 
                        with local laws, if and to the extent local laws are applicable.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400 mt-4">
                        The Services are not tailored to comply with industry-specific regulations (Health Insurance Portability and 
                        Accountability Act (HIPAA), Federal Information Security Management Act (FISMA), etc.), so if your interactions 
                        would be subjected to such laws, you may not use the Services. You may not use the Services in a way that would 
                        violate the Gramm-Leach-Bliley Act (GLBA).
                    </p>
                </section>

                <section className="mb-8" id="ip">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">2. Intellectual Property Rights</h2>
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-2">Our intellectual property</h3>
                        <p className="text-gray-600 dark:text-gray-400">
                            We are the owner or the licensee of all intellectual property rights in our Services, including all source code, 
                            databases, functionality, software, website designs, audio, video, text, photographs, and graphics in the 
                            Services (collectively, the "Content"), as well as the trademarks, service marks, and logos contained therein 
                            (the "Marks").
                        </p>
                        <p className="text-gray-600 dark:text-gray-400 mt-4">
                            Our Content and Marks are protected by copyright and trademark laws (and various other intellectual property 
                            rights and unfair competition laws) and treaties in the United States and around the world.
                        </p>
                        <p className="text-gray-600 dark:text-gray-400 mt-4">
                            The Content and Marks are provided in or through the Services "AS IS" for your personal, non-commercial use or 
                            internal business purpose only.
                        </p>
                    </div>

                    <div className="mb-4">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-2">Your use of our Services</h3>
                        <p className="text-gray-600 dark:text-gray-400">
                            Subject to your compliance with these Legal Terms, including the "Prohibited Activities" section below, we grant 
                            you a non-exclusive, non-transferable, revocable license to:
                        </p>
                        <ul className="list-disc text-gray-600 dark:text-gray-400 mt-2 space-y-1">
                            <li>access the Services; and</li>
                            <li>download or print a copy of any portion of the Content to which you have properly gained access.</li>
                        </ul>
                        <p className="text-gray-600 dark:text-gray-400 mt-4">
                            solely for your personal, non-commercial use or internal business purpose.
                        </p>
                        <p className="text-gray-600 dark:text-gray-400">
                            Except as set out in this section or elsewhere in our Legal Terms, no part of the Services and no Content or Marks may be copied, 
                            reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, 
                            licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.
                        </p>

                        <p className="text-gray-600 dark:text-gray-400">
                            If you wish yo make any use of the Services, Content, or Marks other than as set out in this section or elsewhere in our Legal Terms, 
                            please address your request to <a href="mailto:simon@ask-elly.co" className="text-text dark:text-text-dark hover:underline">simon@ask-elly.co</a>.
                            If we ever grant you the permission to post, reproduce, or publicly display any part of our Services or Content, you must identify us as the 
                            owners of licensors of the Services, Content, or Marks and ensure that any copyright or proprietary notice appears or is visible on posting, 
                            reproducing, or displaying our Content.
                        </p>
                        <p className="text-gray-600 dark:text-gray-400">
                            We reserve all rights not expressly granted to you in and to the Services, Content, and Marks.
                        </p>
                        <p className="text-gray-600 dark:text-gray-400">
                            Any breach of these Intellectual Property Rights will constitute a material breach of our Legal Terms and your right to use our Services will 
                            terminate immediately.
                        </p>
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-2">Your submissions and contributions</h3>
                        <p className="text-gray-600 dark:text-gray-400">
                            Please review this section and the <a href="#prohibited" className="text-text dark:text-text-dark mr-1">Prohibited Activities</a> 
                            section carefully prior to using our Services to understand the (a) rights you give us and (b) obligations you have when you post or upload any 
                            content through the Services.
                        </p>
                        <p className="text-gray-600 dark:text-gray-400">
                            <strong>Submissions:</strong> By directly sending us any question, comment, suggestion, idea, feedback or othe rinformation about the Services 
                            ('Submissions'), you agree to assign to us all intellectial property rights in such Submission. You agree that we shall own this Submission and be 
                            entitled to its unrestricted use and dissemination for any lawful purpose, commercial or otherwise, without acknowledgment or compensation to you.
                        </p>
                        <p className="text-gray-600 dark:text-gray-400">
                            <strong>Contributions:</strong> The Services may invite you to chat, contribute to, or participate in blogs, message boards, online forums, and 
                            other functionality during which you may create, submit, post, display, transmit, publish, distribute, or broadcast content and materials to us 
                            or through the Services, including but not limited to text, writings, video, audio, photographs, music, graphics, comments, reviews, rating 
                            suggestions, personal information, or other material ('Contributions'). Any Submission that is publicly posted shall also be treated as a 
                            Contribution.
                        </p>
                        <p className="text-gray-600 dark:text-gray-400">
                            You understand that Contributions may be viewable by other users of the Services and possibly through third-party websites.
                        </p>
                        <p className="text-gray-600 dark:text-gray-400">
                            <strong>When you post Contributions, you grant us a license:</strong> By posting any Contributions, you grant us an unrestricted, unlimited, 
                            irrevocable, perpetual, worldwide, royalty-free, exclusive, and fully-paid, sublicensable and transferable license to use, store, and copy your 
                            Contributions in connection with the Services.
                        </p>
                        <p className="text-gray-600 dark:text-gray-400">
                            <strong>You are responsible for what you post or upload:</strong> By sending us your Submitssions and/or posting Contributions through any part 
                            of the Services, or making Contributions accessible through the Services, by linking your account through the Services to any of your social 
                            networking accounts, you:
                        </p>
                        <ul className="list-disc text-gray-600 dark:text-gray-400 mt-2 space-y-1">
                            <li>confirm that you have read and agree with our <a href="#prohibited" className="text-text dark:text-text-dark mr-1">Prohibited Activities</a> 
                            and will not post, send, publish, upload, or transmit through the Services any Submission nor post any Contribution that is illegal, harassing, hateful 
                            harmful, defamatory, obscene, bullying, abusive, discriminatory, threatening to any person or group, sexually explicit, false, inaccurate, deceitful, or 
                            misleading;</li>
                            <li>to the extent permissible by applicable law, waive any and all moral rights to any such Submission or Contribution;</li>
                            <li>warrant that any such Submission and/or Contributions are original to you or that you have the necessary rights and licenses to submit such Submissions 
                                and/or Contributions and that you have full authority to grant us the above-mentioned rights in relation to your Submissions and/or Contributions; and</li>
                            <li>warrant and represent that your Submissions and/or Contributions do not constitute confidential information.</li>
                        </ul>
                        <p className="text-gray-600 dark:text-gray-400">
                            You are solely responsible for your Submissions and/or Contributions and you expressly agree to reimburse us for any and all losses that we may suffer because 
                            of your breach of (a) this section, (b) ant third party's intellectual property rights, or (c) applicable law.
                        </p>
                        <p className="text-gray-600 dark:text-gray-400">
                            <strong>We may remove or edit your Content:</strong> Although we have no obligation to monitor any Contributions, we shall have the right to remove or edit 
                            any Contributions at any time without notice if in our reasonbable opinion we consider such contributions harmful or in breach of these Legal Terms. If we 
                            remove or edit any such Contributions, we may also suspend or disable your account and report you to the authorities.
                        </p>
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-2">Copyright infringement</h3>
                        <p className="text-gray-600 dark:text-gray-400">
                            We respect the intellectual property rights of others. If you believe that any material available on or through the Services infringes upon any copyright you own 
                            or control, please immediately refer to the <a href="#copyrightyes" className="text-text dark:text-text-dark mr-1">Copyright Infringements</a> section below.
                        </p>
                    </div>
                </section>

                <section className="mb-8" id="userreps">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">3. User Representations</h2>
                    <p className="text-gray-600 dark:text-gray-400">
                        By using the Services, you represent and warrant that:
                    </p>
                    <ul className="list-disc text-gray-600 dark:text-gray-400 mt-2 space-y-1">
                        <li>all registration information you submit will be true, accurate, current, and complete;</li>
                        <li>you will maintain the accuracy of such information and promptly update such registration information as necessary;</li>
                        <li>you have the legal capacity and you agree to comply with these Legal Terms;</li>
                        <li>you are not under the age of 18;</li>
                        <li>you are not a minor in the jurisdiction in which you reside;</li>
                        <li>you will not access the Services through automated or non-human means, whether through a bot, script or otherwise;</li>
                        <li>you will not use the Services for any illegal or unauthorized purpose;</li>
                        <li>your use of the Services will not violate any applicable law or regulation.</li>
                    </ul>
                    <p className="text-gray-600 dark:text-gray-400 mt-4">
                        If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend 
                        or terminate your account and refuse any and all current or future use of the Services (or any portion thereof).
                    </p>
                </section>

                <section className="mb-8" id="userreg">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">4. User Registration</h2>
                    <p className="text-gray-600 dark:text-gray-400">
                        You may be required to register to use the Services. You agree to keep your password confidential and 
                        will be responsible for all use of your account and password. We reserve the right to remove, reclaim, 
                        or change a username you select if we determine, in our sole discretion, that such username is inappropriate, 
                        obscene, or otherwise objectionable.
                    </p>
                </section>

                <section className="mb-8" id="purchases">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">5. Purchases and Payment</h2>
                    <p className="text-gray-600 dark:text-gray-400">
                        We accept the following forms of payment, via Stripe, our payment provider:
                    </p>
                    <ul className="list-disc text-gray-600 dark:text-gray-400 mt-2 space-y-1">
                        <li>Visa</li>
                        <li>Mastercard</li>
                        <li>American Express</li>
                    </ul>
                    <p className="text-gray-600 dark:text-gray-400 mt-4">
                        You agree to provide current, complete, and accurate purchase and account information for all purchases 
                        made via the Services. You further agree to promptly update account and payment information, including 
                        email address, payment method, and payment card expiration date, so that we can complete your transactions 
                        and contact you as needed. Sales tax will be added to the price of purchases as deemed required by us. We may 
                        change prices at any time. All payments shall be in Euros.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400 mt-4">
                        You agree to pay all charges at the prices then in effect for your purchases, and you authorise us to charge 
                        your chosen payment provider for any sucg amounts upon placing your order. We reserve the right to correct any 
                        errors or mistakes in pricing, even if we have already requested or received payment.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400 mt-4">
                        We reserve the right to refuse any order placed through the Services. We may, in our sole discretion, 
                        limit or cancel quantities purchased per person, per household, or per order. These restrictions may 
                        include orders placed by or under the same customer account, the same payment method, and/or orders 
                        that use the same billing or shipping address. We reserve the right to limit or prohibit orders that, in our 
                        sole judgement, appear to be placed by dealers, resellers, or distributors.
                    </p>
                </section>

                <section className="mb-8" id="subscriptions">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">6. Subscriptions</h2>
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-2">Billing and renewal</h3>
                        <p className="text-gray-600 dark:text-gray-400">
                            Your subscription will continue and automatically renew unless cancelled. You consent to our charging your 
                            payment method on a recurring basis without requiring your prior approval for reach requrring charge, until 
                            such time as you cancel the applicable order. The length of your billing cycle is monthly.
                        </p>
                    </div>
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-2">Free Trial</h3>
                        <p className="text-gray-600 dark:text-gray-400">
                            We may, at our sole discretion, offer a subscription with a free trial for a limited period of time. 
                            You may be required to enter your billing information in order to sign up for the free trial.
                        </p>
                        <p className="text-gray-600 dark:text-gray-400 mt-4">
                            If you do enter your billing information when signing up for a free trial, you will not be charged 
                            by us until the free trial has expired. On the last day of the free trial period, unless you cancelled 
                            your subscription, you will be automatically charged the applicable subscription fee for the type of 
                            subscription you have selected.
                        </p>
                    </div>
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-2">Cancellation</h3>
                        <p className="text-gray-600 dark:text-gray-400">
                            All purchases are non-refundable. You can cancel your subscription at any time by logging into your account 
                            or contacting us at 
                            <a href="mailto:support@ask-elly.co" className="text-text dark:text-text-dark ml-1">support@ask-elly.co</a>.
                            Your cancellation will take effect at the end of your current paid term.    
                            If you have any questions or are unsatisfied with our Services, please contact us at 
                            <a href="mailto:support@ask-elly.co" className="text-text dark:text-text-dark ml-1">support@ask-elly.co</a>.
                        </p>
                    </div>
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-2">Fee Changes</h3>
                        <p className="text-gray-600 dark:text-gray-400">
                            We may, from time to time, make changes to the subscription fees and will communicate any price changes to you 
                            in accordance with applicable law.
                        </p>
                    </div>
                </section>

                <section className="mb-8" id="prohibited">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">7. Prohibited Activities</h2>
                    <p className="text-gray-600 dark:text-gray-400">
                        You may not access or use the Services for any purpose other than that for which we make the Services available. 
                        The Services may not be used in connection with any commercial endeavors except those that are specifically 
                        endorsed or approved by us.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400 mt-4">
                        As a user of the Services, you agree not to:
                    </p>
                    <ul className="list-disc text-gray-600 dark:text-gray-400 mt-2 space-y-1">
                        <li>Systematically retrieve data or other content from the Services to create or compile, directly or indirectly, a 
                            collection, compilation, database, or directory without written permission from us.</li>
                        <li>Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information 
                            such as user passwords.</li>
                        <li>Circumvent, disable, or otherwise interfere with security-related features of the Services, including features 
                            that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Services 
                            and/or the Content contained therein.</li>
                        <li>Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services.</li>
                        <li>Use any information obtained from the Services in order to harass, abuse, or harm another person.</li>
                        <li>Make improper use of our support services or submit false reports of abuse or misconduct.</li>
                        <li>Use the Services in a manner inconsistent with any applicable laws or regulations.</li>
                        <li>Engage in unauthorized framing of or linking to the Services.</li>
                        <li>Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including 
                            excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes 
                            with any party's uninterrupted use and enjoyment of the Services, or modifies, impairs, disrupts, alters, or 
                            interferes with the use, features, functions, operation, or maintenance of the Services. </li>
                        <li>Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data 
                            mining, robots or similar data gathering and extraction tools</li>
                        <li>Delete the copyright or other proprietary rights notice from any Content.</li>
                        <li>Attempt to impersonate another user or person or use the username of another user.</li>
                        <li>Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active 
                            information collection or transmission mechanism, including without limitation, clear graphics interchange 
                            formats ("gifs"), 1x1 pixels, web bugs, cookies, and other similar devices (sometimes referred to as "spyware" 
                            or "passive collection mechanisms" or "pcms").</li>
                        <li>Interfere with, disrupt, or create an undue burden on the Services or the networks or services connected to the 
                            Services.</li>
                        <li>Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the 
                            Services to you.</li>
                        <li>Attempt to bypass any measures of the Services designed to prevent or restrict access to the Services, or any 
                            portion of the Services.</li>
                        <li>Copy or adapt the Services' software, including but not limited to Flash, PHP, HTML, JavaScript or other code.</li>
                        <li>Except as permitted by applicable law, decipher, decompile, disassemble, or reverse engineer any of the software 
                            comprising or in any way making up a part of the Services.</li>
                        <li>Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute 
                            any automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline reader 
                            that accesses the Services, or using or launching any unauthorized script or other software.</li>
                        <li>Use a buying agent or purchasing agent to make purchases on the Services.</li>
                        <li>Make any unauthorized use of the Services, including collecting usernames and/or email addresses of users 
                            by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated 
                            means or under false pretenses.</li>
                        <li>Use the Services as part of any effort to compete with us or otherwise use the Services for any revenue-generating 
                            endeavor or commercial enterprise.</li>
                        <li>Sell or otherwise transfer your profile.</li>
                    </ul>
                </section>

                <section className="mb-8" id="ugc">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">8. User Generated Contributions</h2>
                    <p className="text-gray-600 dark:text-gray-400">
                        The Services may invite you to chat, contribute to, or participate in blogs, message boards, online forums, 
                        and other functionality, and may provide you with the opportunity to create, submit, post, display, transmit, 
                        perform, publish, distribute, or broadcast content and materials to us or on the Services, including but not 
                        limited to text, writings, video, audio, photographs, graphics, comments, suggestions, or personal information 
                        or other material (collectively, "Contributions").
                    </p>
                    <p className="text-gray-600 dark:text-gray-400 mt-4">
                        Any Contributions you transmit to us will be treated as non-confidential and non-proprietary. When you create 
                        or make available any Contributions, you thereby represent and warrant that:
                    </p>
                    <ul className="list-disc text-gray-600 dark:text-gray-400 mt-2 space-y-1">
                        <li>The creation, distribution, transmission, public display, or performance, and the accessing, downloading, or 
                            copying of your Contributions do not and will not infringe the proprietary rights, including but not 
                            limited to the copyright, patent, trademark, trade secret, or moral rights of any third party.</li>
                        <li>You are the creator and owner of or have the necessary licenses, rights, consents, releases, and permissions 
                            to use and to authorize us, the Services, and other users of the Services to use your Contributions in any 
                            manner contemplated by the Services and these Legal Terms.</li>
                        <li>You have the written consent, release, and/or permission of each and every identifiable individual person 
                            in your Contributions to use the name or likeness of each and every such identifiable individual person to 
                            enable inclusion and use of your Contributions in any manner contemplated by the Services and these Legal Terms.</li>
                        <li>Your Contributions are not false, inaccurate, or misleading.</li>
                        <li>Your Contributions are not unsolicited or unauthorized advertising, promotional materials, pyramid schemes, 
                            chain letters, spam, mass mailings, or other forms of solicitation.</li>
                        <li>Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libellous, slanderous, or 
                            otherwise objectionable (as determined by us).</li>
                        <li>Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.</li>
                        <li>Your Contributions are not used to harass or threaten (in the legal sense of those terms) any other person 
                            and to promote violence against a specific person or class of people.</li>
                        <li>Your Contributions do not violate any applicable law, regulation, or rule.</li>
                        <li>Your Contributions do not violate the privacy or publicity rights of any third party.</li>
                        <li>Your Contributions do not violate any applicable law concerning child pornography, or otherwise intended to protect 
                            the health or well-being of minors.</li>
                        <li>Your Contributions do not include any offensive comments that are connected to race, national origin, gender, sexual 
                            preference, or physical handicap.
                        </li>
                        <li>Your Contributions do not otherwise violate, or link to material that violates any provision of these Legal Terms, 
                            or any applicable law or regulation.
                        </li>
                    </ul>
                    <p className="text-gray-600 dark:text-gray-400">
                        Any use of the Services in violation of the foregoing violates these Legal Terms and may result in, among other things, 
                        termination or suspension of your rights to use the Services.
                    </p>
                </section>
                <section className="mb-8" id="license">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">9. Contribution License</h2>
                    <p className="text-gray-600 dark:text-gray-400">
                        By posting your Contributions to any part of the Services, you automatically grant, and you represent and 
                        warrant that you have the right to grant, to us an unrestricted, unlimited, irrevocable, perpetual, 
                        non-exclusive, transferable, royalty-free, fully-paid, worldwide right, and license to use, copy, and 
                        store your Contributions.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400 mt-4">
                        We do not assert any ownership over your Contributions. You retain full ownership of all of your Contributions 
                        and any intellectual property rights or other proprietary rights associated with your Contributions. We are not 
                        liable for any statements or representations in your Contributions provided by you in any area on the Services.
                        You are solely responsible for your Contributions to the Services and you expressly agree to exonerate us from any 
                        and all responsibility and to refrain from any legal action against us regarding your Contributions.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400 mt-4">
                        We have the right, in our sole and absolute discretion, (1) to edit, redact, or otherwise change and Contributions; 
                        (2) to re-categorize any Contributions to place them in more appropriate locations on the Services; and (3) to pre-screen 
                        or delete any Contributions at any time and for any reason, without notice. We have no obligation to monitor your 
                        Contributions.
                    </p>
                </section>

                <section className="mb-8" id="reviews">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">10. Guidelines for Reviews</h2>
                    <p className="text-gray-600 dark:text-gray-400">
                        We may provide you areas on the Services to leave reviews or ratings. When posting a review, you must comply 
                        with the following criteria:
                    </p>
                    <ul className="list-disc text-gray-600 dark:text-gray-400 mt-2 space-y-1">
                        <li>you should have firsthand experience with the person/entity being reviewed;</li>
                        <li>your reviews should not contain offensive profanity, or abusive, racist, offensive, or hateful language;</li>
                        <li>your reviews should not contain discriminatory references based on religion, race, gender, national origin, 
                            age, marital status, sexual orientation, or disability;</li>
                        <li>your reviews should not contain references to illegal activity;</li>
                        <li>you should not be affiliated with competitors if posting negative reviews;</li>
                        <li>you should not make any conclusions as to the legality of conduct;</li>
                        <li>you may not post any false or misleading statements;</li>
                        <li>you may not organize a campaign encouraging others to post reviews, whether positive or negative.</li>
                    </ul>
                    <p className="text-gray-600 dark:text-gray-400 mt-4">
                        We may accept, reject, or remove reviews in our sole discretion. We have absolutely no obligation to screen 
                        reviews or to delete reviews, even if anyone considers reviews objectionable or inaccurate. Reviews are not 
                        endorsed by us, and do not necessarily represent our opinions or the views of any of our affiliates or partners.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400 mt-4">
                        We do not assume liability for any review or for any claims, liabilities, or losses resulting from any review. 
                        By posting a review, you hereby grant to us a perpetual, non-exclusive, worldwide, royalty-free, fully-paid, 
                        assignable, and sublicensable right and license to reproduce, modify, translate, transmit by any means, display, 
                        perform, and/or distribute all content relating to reviews.
                    </p>
                </section>

                <section className="mb-8" id="socialmedia">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">11. Social Media</h2>
                    <p className="text-gray-600 dark:text-gray-400">
                        As part of the functionality of the Services, you may link your account with online accounts you have with 
                        third-party service providers (each such account, a 'Third-Party Account') by either: (1) providing your 
                        Third-Party Account login information through the Services; or (2) allowing us to access your Third-Party 
                        Account, as is permitted under the applicable terms and conditions that govern your use of each Third-Party Account.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400 mt-4">
                        You represent and warrant that you are entitled to disclose your Third-Party Account login information to us 
                        and/or grant us access to your Third-Party Account, without breach by you of any of the terms and conditions 
                        that govern your use of the applicable Third-Party Account, and without obligating us to pay any fees or making 
                        us subject to any usage limitations imposed by the third-party service provider of the Third-Party Account.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400 mt-4">
                        By granting us access to any Third-Party Accounts, you understand that (1) we may access, make available, and 
                        store (if applicable) any content that you have provided to and stored in your Third-Party Account (the 'Social 
                        Network Content') so that it is available on and through the Services via your account, and (2) we may submit to 
                        and receive from your Third-Party Account additional information to the extent you are notified when you link your 
                        account with the Third-Party Account.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400 mt-4">
                        Depending on the Third-Party Accounts you choose and subject to the privacy settings that you have set in such 
                        Third-Party Accounts, personally identifiable information that you post to your Third-Party Accounts may be 
                        available on and through your account on the Services.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400 mt-4">
                        Please note that if a Third-Party Account or associated service becomes unavailable or our access to such 
                        Third-Party Account is terminated by the third-party service provider, then Social Network Content may no 
                        longer be available on and through the Services. You will have the ability to disable the connection between 
                        your account on the Services and your Third-Party Accounts at any time.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400 mt-4">
                        PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY 
                        ACCOUNTS IS GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE PROVIDERS.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400 mt-4">
                        We make no effort to review any Social Network Content for any purpose, including but not limited to, for 
                        accuracy, legality, or non-infringement, and we are not responsible for any Social Network Content.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400 mt-4">
                        You can deactivate the connection between the Services and your Third-Party Account by contacting us using 
                        the contact information below or through your account settings (if applicable). We will attempt to delete 
                        any information stored on our servers that was obtained through such Third-Party Account, except the username 
                        and profile picture that become associated with your account.
                    </p>
                </section>

                <section className="mb-8" id="thirdparty">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">12. Third-Party Websites and Content</h2>
                    <p className="text-gray-600 dark:text-gray-400">
                        The Services may contain (or you may be sent via the Site) links to other websites ('Third-Party Websites') 
                        as well as articles, photographs, text, graphics, pictures, designs, music, sound, video, information, applications, 
                        software, and other content or items belonging to or originating from third parties ('Third-Party Content').
                    </p>
                    <p className="text-gray-600 dark:text-gray-400 mt-4">
                        Such Third-Party Websites and Third-Party Content are not investigated, monitored, or checked for accuracy, 
                        appropriateness, or completeness by us, and we are not responsible for any Third-Party Websites accessed through 
                        the Services or any Third-Party Content posted on, available through, or installed from the Services, including 
                        the content, accuracy, offensiveness, opinions, reliability, privacy practices, or other policies of or contained 
                        in the Third-Party Websites or the Third-Party Content.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400 mt-4">
                        Inclusion of, linking to, or permitting the use or installation of any Third-Party Websites or any Third-Party 
                        Content does not imply approval or endorsement thereof by us. If you decide to leave the Services and access the 
                        Third-Party Websites or to use or install any Third-Party Content, you do so at your own risk, and you should be 
                        aware these Legal Terms no longer govern.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400 mt-4">
                        You should review the applicable terms and policies, including privacy and data gathering practices, of any website 
                        to which you navigate from the Services or relating to any applications you use or install from the Services. Any 
                        purchases you make through Third-Party Websites will be through other websites and from other companies, and we 
                        take no responsibility whatsoever in relation to such purchases which are exclusively between you and the applicable 
                        third party.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400 mt-4">
                        You agree and acknowledge that we do not endorse the products or services offered on Third-Party Websites and you 
                        shall hold us blameless from any harm caused by your purchase of such products or services. Additionally, you shall 
                        hold us blameless from any losses sustained by you or harm caused to you relating to or resulting in any way from 
                        any Third-Party Content or any contact with Third-Party Websites.
                    </p>
                </section>

                <section className="mb-8" id="sitemanage">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">13. Services Management</h2>
                    <p className="text-gray-600 dark:text-gray-400">
                        We reserve the right, but not the obligation, to:
                    </p>
                    <ul className="list-disc text-gray-600 dark:text-gray-400 mt-2 space-y-1">
                        <li>monitor the Services for violations of these Legal Terms;</li>
                        <li>take appropriate legal action against anyone who, in our sole discretion, violates the law or these Legal Terms, 
                            including without limitation, reporting such user to law enforcement authorities;</li>
                        <li>in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or 
                            disable (to the extent technologically feasible) any of your Contributions or any portion thereof;</li>
                        <li>in our sole discretion and without limitation, notice, or liability, to remove from the Services or otherwise 
                            disable all files and content that are excessive in size or are in any way burdensome to our systems;</li>
                        <li>otherwise manage the Services in a manner designed to protect our rights and property and to facilitate the 
                            proper functioning of the Services.</li>
                    </ul>
                </section>

                <section className="mb-8" id="ppyes">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">14. Privacy Policy</h2>
                    <p className="text-gray-600 dark:text-gray-400">
                        We care about data privacy and security. Please review our Privacy Policy: 
                        <a href="/privacy" className="text-text dark:text-text-dark hover:underline ml-1">
                            https://ask-elly.co/privacy
                        </a>
                    </p>
                    <p className="text-gray-600 dark:text-gray-400 mt-4">
                        By using the Services, you agree to be bound by our Privacy Policy, which is incorporated into these Legal Terms. 
                        Please be advised the Services are hosted in Ireland. If you access the Services from any other region of the world 
                        with laws or other requirements governing personal data collection, use, or disclosure that differ from applicable 
                        laws in Ireland, then through your continued use of the Services, you are transferring your data to Ireland, and 
                        you expressly consent to have your data transferred to and processed in Ireland.
                    </p>
                </section>

                <section className="mb-8" id="copyrightyes">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">15. Copyright Infringements</h2>
                    <p className="text-gray-600 dark:text-gray-400">
                        We respect the intellectual property rights of others. If you believe that any material available on or through 
                        the Services infringes upon any copyright you own or control, please immediately notify us using the contact 
                        information provided below (a "Notification").
                    </p>
                    <p className="text-gray-600 dark:text-gray-400 mt-4">
                        Please be advised that pursuant to applicable law you may be held liable for damages if you make material 
                        misrepresentations in a Notification. Thus, if you are not sure that material located on or linked to by the 
                        Services infringes your copyright, you should consider first contacting an attorney.
                    </p>
                </section>

                <section className="mb-8" id="terms">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">16. Term and Termination</h2>
                    <p className="text-gray-600 dark:text-gray-400">
                        These Legal Terms shall remain in full force and effect while you use the Services. WITHOUT LIMITING ANY OTHER 
                        PROVISION OF THESE LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, 
                        DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON 
                        OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED 
                        IN THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE 
                        SERVICES OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, 
                        IN OUR SOLE DISCRETION.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400 mt-4">
                        If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new 
                        account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting 
                        on behalf of the third party. In addition to terminating or suspending your account, we reserve the right to 
                        take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.
                    </p>
                </section>

                <section className="mb-8" id="modifications">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">17. Modifications and Interruptions</h2>
                    <p className="text-gray-600 dark:text-gray-400">
                        We reserve the right to change, modify, or remove the contents of the Services at any time or for any reason 
                        at our sole discretion without notice. However, we have no obligation to update any information on our Services. 
                        We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance 
                        of the Services.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400 mt-4">
                        We cannot guarantee the Services will be available at all times. We may experience hardware, software, or other 
                        problems or need to perform maintenance related to the Services, resulting in interruptions, delays, or errors. 
                        We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Services at any 
                        time or for any reason without notice to you. You agree that we have no liability whatsoever for any loss, 
                        damage, or inconvenience caused by your inability to access or use the Services during any downtime or 
                        discontinuance of the Services. Nothing in these Legal Terms will be construed to obligate us to maintain 
                        and support the Services or to supply any corrections, updates, or releases in connection therewith.
                    </p>
                </section>

                <section className="mb-8" id="law">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">18. Governing Law</h2>
                    <p className="text-gray-600 dark:text-gray-400">
                        These Legal Terms are governed by and interpreted following the laws of Denmark, and the use of the United 
                        Nations Convention of Contracts for the International Sales of Goods is expressly excluded. If your habitual 
                        residence is in the EU, and you are a consumer, you additionally possess the protection provided to you by 
                        obligatory provisions of the law in your country to residence. Elly ApS and yourself both agree to submit 
                        to the non-exclusive jurisdiction of the courts of Copenhagen, which means that you may make a claim to 
                        defend your consumer protection rights in regards to these Legal Terms in Denmark, or in the EU country 
                        in which you reside.
                    </p>
                </section>

                <section className="mb-8" id="disputes">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">19. Dispute Resolution</h2>
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-2">Informal Negotiations</h3>
                        <p className="text-gray-600 dark:text-gray-400">
                            To expedite resolution and control the cost of any dispute, controversy, or claim related to these Legal 
                            Terms (each a "Dispute" and collectively, the "Disputes") brought by either you or us (individually, a 
                            "Party" and collectively, the "Parties"), the Parties agree to first attempt to negotiate any Dispute 
                            (except those Disputes expressly provided below) informally for at least thirty (30) days before initiating 
                            arbitration. Such informal negotiations commence upon written notice from one Party to the other Party.
                        </p>
                    </div>
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-2">Binding Arbitration</h3>
                        <p className="text-gray-600 dark:text-gray-400">
                            Any dispute arising out of or in connection with these Legal Terms, including any disputes regarding the 
                            existence, validity, or termination thereof, shall be settled by arbitration in accordance with the Rules 
                            of Arbitration of the Danish Institute of Arbitration in force at the time when such proceedings are commenced.
                        </p>
                    </div>
                </section>

                <section className="mb-8" id="disputes">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">19. Dispute Resolution</h2>
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-2">Informal Negotiations</h3>
                        <p className="text-gray-600 dark:text-gray-400">
                            To expedite resolution and control the cost of any dispute, controversy, or claim related to these Legal 
                            Terms (each a 'Dispute' and collectively, the 'Disputes') brought by either you or us (individually, a 
                            'Party' and collectively, the 'Parties'), the Parties agree to first attempt to negotiate any Dispute 
                            (except those Disputes expressly provided below) informally for at least thirty (30) days before initiating 
                            arbitration. Such informal negotiations commence upon written notice from one Party to the other Party.
                        </p>
                    </div>

                    <div className="mb-4">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-2">Binding Arbitration</h3>
                        <p className="text-gray-600 dark:text-gray-400">
                            Any dispute arising from the relationships between the Parties to these Legal Terms shall be determined by 
                            one arbitrator who will be chosen in accordance with the Arbitration and Internal Rules of the European 
                            Court of Arbitration being part of the European Centre of Arbitration having its seat in Strasbourg, and 
                            which are in force at the time the application for arbitration is filed, and of which adoption of this 
                            clause constitutes acceptance. The seat of arbitration shall be Copenhagen, Denmark. The language of the 
                            proceedings shall be English. Applicable rules of substantive law shall be the law of Denmark.
                        </p>
                    </div>

                    <div className="mb-4">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-2">Restrictions</h3>
                        <p className="text-gray-600 dark:text-gray-400">
                            The Parties agree that any arbitration shall be limited to the Dispute between the Parties individually. 
                            To the full extent permitted by law, (a) no arbitration shall be joined with any other proceeding; (b) 
                            there is no right or authority for any Dispute to be arbitrated on a class-action basis or to utilise 
                            class action procedures; and (c) there is no right or authority for any Dispute to be brought in a 
                            purported representative capacity on behalf of the general public or any other persons.
                        </p>
                    </div>

                    <div className="mb-4">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-2">Exceptions to Informal Negotiations and Arbitration</h3>
                        <p className="text-gray-600 dark:text-gray-400">
                            The Parties agree that the following Disputes are not subject to the above provisions concerning informal 
                            negotiations binding arbitration: (a) any Disputes seeking to enforce or protect, or concerning the validity 
                            of, any of the intellectual property rights of a Party; (b) any Dispute related to, or arising from, 
                            allegations of theft, piracy, invasion of privacy, or unauthorised use; and (c) any claim for injunctive 
                            relief. If this provision is found to be illegal or unenforceable, then neither Party will elect to 
                            arbitrate any Dispute falling within that portion of this provision found to be illegal or unenforceable 
                            and such Dispute shall be decided by a court of competent jurisdiction within the courts listed for 
                            jurisdiction above, and the Parties agree to submit to the personal jurisdiction of that court.
                        </p>
                    </div>
                </section>

                <section className="mb-8" id="corrections">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">20. Corrections</h2>
                    <p className="text-gray-600 dark:text-gray-400">
                        There may be information on the Services that contains typographical errors, inaccuracies, or omissions, 
                        including descriptions, pricing, availability, and various other information. We reserve the right to correct 
                        any errors, inaccuracies, or omissions and to change or update the information on the Services at any time, 
                        without prior notice.
                    </p>
                </section>

                <section className="mb-8" id="disclaimer">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">21. Disclaimer</h2>
                    <p className="text-gray-600 dark:text-gray-400">
                        THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES 
                        WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS 
                        OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE 
                        IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE 
                        NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES' CONTENT OR THE 
                        CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE WILL ASSUME NO LIABILITY 
                        OR RESPONSIBILITY FOR ANY:
                    </p>
                    <ul className="list-decimal text-gray-600 dark:text-gray-400 mt-4 ml-6 space-y-2">
                        <li>ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS,</li>
                        <li>PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE 
                            OF THE SERVICES,</li>
                        <li>UNAUTHORISED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR 
                            FINANCIAL INFORMATION STORED THEREIN,</li>
                        <li>ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES,</li>
                        <li>ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES 
                            BY ANY THIRD PARTY, AND/OR</li>
                        <li>ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED 
                            AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES.</li>
                    </ul>
                    <p className="text-gray-600 dark:text-gray-400 mt-4">
                        WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED 
                        OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE 
                        APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY 
                        BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS 
                        OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, 
                        YOU SHOULD USE YOUR BEST JUDGEMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
                    </p>
                </section>

                <section className="mb-8" id="liability">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">22. Limitations of Liability</h2>
                    <p className="text-gray-600 dark:text-gray-400">
                        IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY 
                        DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST 
                        PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF 
                        WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400 mt-4">
                        NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE 
                        WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT 
                        PAID, IF ANY, BY YOU TO US DURING THE SIX (6) MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400 mt-4">
                        CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE 
                        EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE 
                        DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
                    </p>
                </section>

                <section className="mb-8" id="indemnification">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">23. Indemnification</h2>
                    <p className="text-gray-600 dark:text-gray-400">
                        You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of 
                        our respective officers, agents, partners, and employees, from and against any loss, damage, liability, 
                        claim, or demand, including reasonable attorneys' fees and expenses, made by any third party due to or 
                        arising out of:
                    </p>
                    <ul className="list-decimal text-gray-600 dark:text-gray-400 mt-2 ml-6 space-y-1">
                        <li>your Contributions;</li>
                        <li>use of the Services;</li>
                        <li>breach of these Legal Terms;</li>
                        <li>any breach of your representations and warranties set forth in these Legal Terms;</li>
                        <li>your violation of the rights of a third party, including but not limited to intellectual property rights;</li>
                        <li>any overt harmful act toward any other user of the Services with whom you connected via the Services.</li>
                    </ul>
                    <p className="text-gray-600 dark:text-gray-400 mt-4">
                        Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defence 
                        and control of any matter for which you are required to indemnify us, and you agree to cooperate, at 
                        your expense, with our defence of such claims. We will use reasonable efforts to notify you of any such 
                        claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.
                    </p>
                </section>

                <section className="mb-8" id="userdata">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">24. User Data</h2>
                    <p className="text-gray-600 dark:text-gray-400">
                        We will maintain certain data that you transmit to the Services for the purpose of managing the performance 
                        of the Services, as well as data relating to your use of the Services. Although we perform regular routine 
                        backups of data, you are solely responsible for all data that you transmit or that relates to any activity 
                        you have undertaken using the Services. You agree that we shall have no liability to you for any loss or 
                        corruption of any such data, and you hereby waive any right of action against us arising from any such 
                        loss or corruption of such data.
                    </p>
                </section>

                <section className="mb-8" id="electronic">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">
                        25. Electronic Communications, Transactions, and Signatures
                    </h2>
                    <p className="text-gray-600 dark:text-gray-400">
                        Visiting the Services, sending us emails, and completing online forms constitute electronic communications. 
                        You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, 
                        and other communications we provide to you electronically, via email and on the Services, satisfy any 
                        legal requirement that such communication be in writing.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400 mt-4">
                        YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO 
                        ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US 
                        OR VIA THE SERVICES.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400 mt-4">
                        You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or 
                        other laws in any jurisdiction which require an original signature or delivery or retention of 
                        non-electronic records, or to payments or the granting of credits by any means other than electronic means.
                    </p>
                </section>

                <section className="mb-8" id="california">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">26. California Users and Residents</h2>
                    <p className="text-gray-600 dark:text-gray-400">
                        If any complaint with us is not satisfactorily resolved, you can contact the Complaint Assistance Unit 
                        of the Division of Consumer Services of the California Department of Consumer Affairs in writing at 
                        1625 North Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone at (800) 952-5210 
                        or (916) 445-1254.
                    </p>
                </section>

                <section className="mb-8" id="misc">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">27. Miscellaneous</h2>
                    <p className="text-gray-600 dark:text-gray-400">
                        These Legal Terms and any policies or operating rules posted by us on the Services or in respect to 
                        the Services constitute the entire agreement and understanding between you and us. Our failure to 
                        exercise or enforce any right or provision of these Legal Terms shall not operate as a waiver of 
                        such right or provision.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400 mt-4">
                        These Legal Terms operate to the fullest extent permissible by law. We may assign any or all of our 
                        rights and obligations to others at any time. We shall not be responsible or liable for any loss, 
                        damage, delay, or failure to act caused by any cause beyond our reasonable control.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400 mt-4">
                        If any provision or part of a provision of these Legal Terms is determined to be unlawful, void, or 
                        unenforceable, that provision or part of the provision is deemed severable from these Legal Terms 
                        and does not affect the validity and enforceability of any remaining provisions.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400 mt-4">
                        There is no joint venture, partnership, employment or agency relationship created between you and us 
                        as a result of these Legal Terms or use of the Services. You agree that these Legal Terms will not 
                        be construed against us by virtue of having drafted them. You hereby waive any and all defences you 
                        may have based on the electronic form of these Legal Terms and the lack of signing by the parties 
                        hereto to execute these Legal Terms.
                    </p>
                </section>

                <section className="mb-8" id="contact">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">28. Contact Us</h2>
                    <p className="text-gray-600 dark:text-gray-400">
                        In order to resolve a complaint regarding the Services or to receive further information regarding use of the 
                        Services, please contact us on 
                        <a href="mailto:simon@ask-elly.co" className="text-text dark:text-text-dark hover:underline ml-1">
                            simon@ask-elly.co
                        </a>, or by mail at:
                    </p>
                    <p className="text-gray-600 dark:text-gray-400 mt-4">
                        Elly ApS<br />
                        Porcelænshaven 4D, 1. tv<br />
                        Frederiksberg 2000<br />
                        Denmark
                    </p>
                </section>
            </div>
        </div>
    );
}