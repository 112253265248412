import React from 'react';
import { XCircleIcon } from '@heroicons/react/24/solid';

const Attachments = ({ fileAttachments, onRemoveFile }) => {
    if (Object.values(fileAttachments).every(files => files.length === 0)) {
        return null;
    }

    const getFileTypeBadgeColor = (fileType) => {
        const types = {
            'pdf': 'bg-red-100 text-red-800 dark:bg-red-900/30 dark:text-red-300',
            'doc': 'bg-blue-100 text-blue-800 dark:bg-blue-900/30 dark:text-blue-300',
            'docx': 'bg-blue-100 text-blue-800 dark:bg-blue-900/30 dark:text-blue-300',
            'xls': 'bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-300',
            'xlsx': 'bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-300',
            'txt': 'bg-gray-100 text-gray-800 dark:bg-gray-900/30 dark:text-gray-300',
        };
        
        return types[fileType?.toLowerCase()] || 'bg-gray-100 text-gray-800 dark:bg-gray-900/30 dark:text-gray-300';
    };

    return (
        <div className="w-full sm:w-5/6 max-w-[1000px] mx-auto">
            <div className="bg-secondary/70 dark:bg-secondary-dark/70 bg-opacity-50 rounded-2xl p-3 mt-2 mx-4">
                <div className="flex flex-wrap gap-3">
                    {Object.entries(fileAttachments).map(([stateKey, files]) => 
                        files.map((file, index) => (
                            <div 
                                key={`${stateKey}-${index}`} 
                                className="relative group"
                            >
                                {stateKey === 'uploadedImages' ? (
                                    // Image preview
                                    <div className="h-20 max-w-[120px] rounded-lg overflow-hidden border border-border dark:border-border-dark flex items-center justify-center">
                                        <img 
                                            src={file} 
                                            alt="Preview" 
                                            className="max-w-[120px] max-h-[80px] w-auto h-auto object-contain"
                                        />
                                    </div>
                                ) : (
                                    // Document preview
                                    <div className="max-w-[200px] h-20 rounded-lg border border-border dark:border-border-dark bg-white dark:bg-gray-800 flex flex-col items-center justify-center p-2">
                                        {/* File type badge */}
                                        <span className={`
                                            text-xs font-sm px-2 py-1 mb-2 rounded-md
                                            ${getFileTypeBadgeColor(file.fileName?.split('.').pop())}
                                        `}>
                                            {file.fileName?.split('.').pop().toUpperCase() || 'DOC'}
                                        </span>
                                        
                                        {/* Filename truncated */}
                                        <span className="text-sm text-text/70 dark:text-text-dark/70 truncate w-full text-center mb-1">
                                            {file.fileName?.split('.')[0].slice(0, 15)}
                                            {file.fileName?.split('.')[0].length > 15 ? '...' : ''}
                                        </span>
                                    </div>
                                )}
                                
                                {/* Remove button */}
                                <button
                                    onClick={() => onRemoveFile(stateKey, index)}
                                    className="absolute -top-2 -right-2 opacity-0 group-hover:opacity-100 transition-opacity"
                                >
                                    <XCircleIcon className="w-5 h-5 text-gray-500 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-200" />
                                </button>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </div>
    );
};

export default Attachments;