import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import DarkModeToggle from "./DarkModeToggle";
import { toast } from 'react-toastify';
import { API_URL } from '../config/api';
import { Cog6ToothIcon, CreditCardIcon, ChatBubbleBottomCenterTextIcon, ShieldCheckIcon, XMarkIcon } from '@heroicons/react/24/outline';

const SettingsPopup = ({ onClose, darkMode, toggleDarkMode, addSystemPrompt, setAddSystemPrompt, systemPrompt, setSystemPrompt }) => {

    const { user } = useAuth0();
    const [selectedSection, setSelectedSection] = useState('General');
    const [draftSystemPrompt, setDraftSystemPrompt] = useState(systemPrompt);

    useEffect(() => {
      setDraftSystemPrompt(systemPrompt);
  }, [systemPrompt]);

    const handleToggleSystemPrompt = async () => {
        await fetch(`${API_URL}/user/settings/system-prompt/toggle`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
              userId: user.sub, 
              addSystemPrompt: !addSystemPrompt 
            }),
        });
        setAddSystemPrompt(!addSystemPrompt);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        await fetch(`${API_URL}/user/settings/system-prompt/update`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId: user.sub, systemPrompt: draftSystemPrompt }),
        });
        setSystemPrompt(draftSystemPrompt);
        toast.success('Guidelines updated');
    }

    const handleReset = async () => {
        const result = await fetch(`${API_URL}/user/settings/system-prompt/reset`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId: user.sub }),
        });
        const data = await result.json();
        setSystemPrompt(data.result.decrypted_system_prompt);
        toast.success('Guidelines reset');
    }

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-10 backdrop-blur-sm p-2 sm:p-4" onClick={onClose}>
          <div className="bg-white dark:bg-gray-800 rounded-2xl p-3 sm:p-6 w-full max-w-3xl min-h-[90vh] sm:min-h-[610px]" onClick={e => e.stopPropagation()}>
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg sm:text-xl font-bold text-text dark:text-text-dark">
                Settings
              </h2>
              <button
                onClick={onClose}
                className="w-6 h-6 rounded-full flex items-center justify-center text-gray-500 hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-200"
                aria-label="Close settings"
              >
                <XMarkIcon className="w-4 h-4" />
              </button>
            </div>
            <hr className="my-3" />
            <div className="flex flex-col sm:flex-row h-[calc(100%-60px)]">
              <div className="w-full sm:w-1/5 pr-0 sm:pr-2 border-b sm:border-b-0 sm:border-r border-gray-300 dark:border-gray-700 mb-4 sm:mb-0">
                <div className="flex sm:flex-col overflow-x-auto sm:overflow-x-visible pb-2 sm:pb-0">
                  <button
                      onClick={() => setSelectedSection('General')}
                      className={`w-full text-left text-sm px-3 py-2 rounded-lg flex items-center ${selectedSection === 'General' ? 'bg-gray-100 dark:bg-gray-700' : ''}`}
                  >
                      <Cog6ToothIcon className="w-5 h-5 mr-2" />
                      General
                  </button>
                  <button
                        onClick={() => setSelectedSection('Subscription')}
                        className={`w-full text-left text-sm px-3 py-2 rounded-lg flex items-center ${selectedSection === 'Subscription' ? 'bg-gray-100 dark:bg-gray-700' : ''}`}
                    >
                        <CreditCardIcon className="w-5 h-5 mr-2" />
                        Subscription
                  </button>
                  <button
                      onClick={() => setSelectedSection('Prompting')}
                      className={`w-full text-left text-sm px-3 py-2 rounded-lg flex items-center ${selectedSection === 'Prompting' ? 'bg-gray-100 dark:bg-gray-700' : ''}`}
                  >
                      <ChatBubbleBottomCenterTextIcon className="w-5 h-5 mr-2" /> 
                      Prompting
                  </button>
                  <button
                      onClick={() => setSelectedSection('Privacy')}
                      className={`w-full text-left text-sm px-3 py-2 rounded-lg flex items-center ${selectedSection === 'Privacy' ? 'bg-gray-100 dark:bg-gray-700' : ''}`}
                  >
                      <ShieldCheckIcon className="w-5 h-5 mr-2" />
                      Privacy
                  </button>
                </div> 
              </div>
              <div className="w-full sm:w-4/5 px-0 sm:px-3 py-2">
                {selectedSection === 'General' && (
                  <div>
                    <div className="flex justify-between items-center px-2 text-sm">
                      <span className="text-text dark:text-text-dark">Color theme</span>
                      <DarkModeToggle 
                        darkMode={darkMode} 
                        toggleDarkMode={toggleDarkMode} 
                      />
                    </div>
                    <hr className="my-3" />
                    <div className="flex justify-between items-center px-2 text-sm">
                        <span className="text-text dark:text-text-dark">Clear all chats</span>
                        <button
                            className="w-24 h-8 bg-secondary dark:bg-secondary-dark text-text dark:text-text-dark rounded-md text-xs cursor-not-allowed"
                            disabled
                        >
                            Coming soon
                        </button>
                    </div>
                    
                  </div>
                )}
                {selectedSection === 'Subscription' && (
                  <div>
                    <p className="text-text dark:text-text-dark text-sm px-2">Click the button below to manage your subscription (upgrade plan, change payment method, cancel subscription, and more).</p>
                    <br className="my-3" />
                    <p className="text-text dark:text-text-dark text-sm px-2">It opens a new tab, where you can enter your email. Stripe will then send you an email with a link, where you can manage your subscription.</p>
                    <br className="my-3" />
                    <p className="text-text dark:text-text-dark text-sm px-2">If anything is unclear, contact us at <a href="mailto:simon@ask-elly.co" className="text-primary">simon@ask-elly.co</a>.</p>
                    <br className="my-3" />
                    <a
                      href={`https://billing.stripe.com/p/login/${
                        window.location.hostname === 'localhost'
                          ? 'test_28o4hT6cn9no42QeUU'
                          : 'cN29Dq09AcGT3n2000'
                      }?prefilled_email=${user.email}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="w-auto h-8 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors text-xs flex items-center justify-center"
                    >
                      Manage subscription
                    </a>
                  </div>
                )}
                {selectedSection === 'Prompting' && (
                  <div>
                    <p className="text-text dark:text-text-dark text-sm px-2">To improve the quality of the responses you get, we give the models guidelines on how to respond. These guidelines are based on our own research and experience, and you can customize them below.</p>
                    <hr className="my-3" />
                    <div className="flex justify-between items-center px-2 text-sm">
                        <span className="text-text dark:text-text-dark">Use guidelines to enhance responses</span>
                        <div
                            onClick={() => handleToggleSystemPrompt()}
                            className="w-20 h-8 bg-gray-100 dark:bg-gray-500 rounded-md p-1 cursor-pointer relative"
                        >
                            <div 
                            className={`w-8 h-6 bg-white dark:bg-background-dark rounded-md absolute transition-transform duration-300 ${
                                addSystemPrompt ? 'translate-x-0' : 'translate-x-10'
                            }`}
                            />
                            <div className="absolute inset-0 flex items-center justify-between px-2">
                            <span className={`text-sm ${addSystemPrompt ? 'text-text dark:text-text-dark' : 'text-gray-400'}`}>Yes</span>
                            <span className={`text-sm ${addSystemPrompt ? 'text-gray-400' : 'text-text dark:text-text-dark'}`}>No</span>
                            </div>
                        </div>
                    </div>
                    {addSystemPrompt && (
                      <>
                        <hr className="my-3" />
                        <div className="flex justify-between items-center px-2 text-sm">
                            <form 
                                onSubmit={handleSubmit} 
                                className="flex flex-col space-y-4 w-full"
                            >
                                <span className="text-text dark:text-text-dark">View and edit guidelines</span>
                                <textarea
                                    value={draftSystemPrompt}
                                    onChange={(e) => setDraftSystemPrompt(e.target.value)}
                                    className="w-full px-3 py-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:outline-none focus:ring-2 focus:ring-primary lg:focus:ring-0 h-64 "
                                />

                                <div className="flex flex-col sm:flex-row justify-between space-y-2 sm:space-y-0 sm:space-x-2">
                                    <button
                                        type="button"
                                        onClick={handleReset}
                                        className="w-full sm:w-1/2 px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
                                    >
                                        Reset to default
                                    </button>
                                    <button
                                        type="submit"
                                        className="w-full sm:w-1/2 px-4 py-2 bg-primary text-white rounded hover:bg-primary-hover disabled:bg-gray-300 disabled:text-gray-500 transition-colors"
                                        disabled={draftSystemPrompt === systemPrompt}
                                    >
                                        Update guidelines
                                    </button>
                                </div>
                            </form>
                        </div>
                      </>
                    )}
                  </div>
                )}
                {selectedSection === 'Privacy' && (
                  <div>
                    <p className="text-text dark:text-text-dark text-sm px-2">Protecting your privacy has been one of the core principles of Elly since the very beginning. Our philosphy is simple: your conversations are for your eyes only.</p>
                    <hr className="my-3" />
                    <div className="flex items-center text-text dark:text-text-dark text-sm px-2 my-6">
                      <p><strong>We encrypt all your messages.</strong> This means that we don't see the contents of the messages you send nor those you receive.</p>
                    </div>
                    <div className="flex items-center text-text dark:text-text-dark text-sm px-2 my-6">
                      <p><strong>We don't sell your data.</strong> We probably hate those creepy, hyper-personalized ads even more than you do, so we won't contribute to them.</p>
                    </div>
                    <div className="flex items-center text-text dark:text-text-dark text-sm px-2 my-6">
                      <p><strong>Your data isn't used for training.</strong> The model providers do not use your conversations to train their new models.</p>
                    </div>
                    <div className="flex items-center text-text dark:text-text-dark text-sm px-2 my-6">
                      <p><strong>We anonymize your prompts.</strong> We don't share your user ID, name or email with the model providers. They only see the prompts you send.</p>
                    </div>
                    <div className="flex items-center text-text dark:text-text-dark text-sm px-2 my-6">
                      <p><strong>You're in control.</strong> We've made it easy for you to delete the contents of your conversations, both individually and all-at-once.</p>
                    </div>
                    <hr className="my-3" />
                    <p className="text-text dark:text-text-dark text-sm px-2">If you have any questions or concerns about our privacy practices, please don't hesitate to contact us.</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
};

export default SettingsPopup;