import OpenAILogoDark from '../assets/logo-openai-dark.svg';
import OpenAILogoLight from '../assets/logo-openai-light.svg';
import ClaudeLogoDark from '../assets/logo-claude-dark.svg';
import ClaudeLogoLight from '../assets/logo-claude-light.svg';
import GeminiLogo from '../assets/logo-gemini-dark.png';
import EllyLogo from '../assets/logo-elly.png';
import MistralLogo from '../assets/logo-mistral.png';
import PerplexicaLogo from '../assets/logo-perplexica.png';

export const getModelInfo = (darkMode) => [
    {
        id: 'claude-3-5-haiku-20241022',
        versions: ['claude-3-5-haiku-20241022'],
        name: 'Claude Haiku 3.5',
        logo: darkMode ? ClaudeLogoLight : ClaudeLogoDark,
        developer: 'Anthropic',
        description: 'Anthropic\'s fastest and most compact model for near-instant responsiveness',
        strength: 'Quick, simple tasks',
        contextWindow: '200k',
        parameters: 'unknown',
        multilingual: true,
        vision: false,
        imageGeneration: false,
        search: false,
        trainingDataCutoff: 'July 2024',
        cost: 13,
        speed: 7,
        hidden: false
    },
    {
        id: 'gemini-1.5-flash',
        versions: ['gemini-1.5-flash'],
        name: 'Gemini 1.5 Flash',
        logo: GeminiLogo,
        developer: 'Google',
        description: 'Google\'s fastest, most cost-efficient multimodal model with great performance for high-frequency tasks',
        strength: 'Quick, simple tasks',
        contextWindow: 'unknown',
        parameters: 'unknown',
        multilingual: true,
        vision: true,
        imageGeneration: false,
        search: false,
        trainingDataCutoff: 'May 2024',
        cost: 4,
        speed: 9,
        hidden: false
    },
    {
        id: 'ministral-3b-latest',
        versions: ['ministral-3b-latest'],
        name: 'Ministral 3B',
        logo: MistralLogo,
        developer: 'Mistral',
        description: 'Mistral\'s 3B model, which they call the world\'s best edge model.',
        strength: 'Quick, simple tasks',
        contextWindow: '128k',
        parameters: '3B parameters',
        multilingual: false,
        vision: false,
        imageGeneration: false,
        search: false,
        trainingDataCutoff: 'Unknown',
        cost: 3,
        speed: 8,
        hidden: false
    },
    {
        id: 'claude-3-5-sonnet-20241022',
        versions: ['claude-3-5-sonnet-20241022', 'claude-3-5-sonnet-20240620'],
        name: 'Claude Sonnet 3.5',
        logo: darkMode ? ClaudeLogoLight : ClaudeLogoDark,
        developer: 'Anthropic',
        description: 'Anthropic\'s most intelligent model, excels in analysis and coding tasks.',
        strength: 'Complex tasks',
        contextWindow: '200k',
        parameters: 'unknown',
        multilingual: true,
        vision: true,
        imageGeneration: false,
        search: false,
        trainingDataCutoff: 'April 2024',
        cost: 33,
        speed: 7,
        hidden: false
    },
    {
        id: 'gpt-4o',
        versions: ['gpt-4o'],
        name: 'GPT-4o',
        logo: darkMode ? OpenAILogoLight : OpenAILogoDark,
        developer: 'OpenAI',
        description: 'OpenAI\'s high-intelligence flagship model for complex, multi-step tasks',
        strength: 'Complex tasks',
        contextWindow: '128k',
        parameters: '175B parameters',
        multilingual: true,
        vision: true,
        imageGeneration: false,
        search: false,
        trainingDataCutoff: 'October 2023',
        cost: 24,
        speed: 9,
        hidden: false
    },
    {
        id: 'o1-preview',
        versions: ['o1-preview'],
        name: 'o1 preview',
        logo: darkMode ? OpenAILogoLight : OpenAILogoDark,
        developer: 'OpenAI',
        description: 'OpenAI\'s advanced reasoning model excelling in science, coding and math',
        strength: 'Reasoning',
        contextWindow: '128k',
        parameters: '',
        multilingual: true,
        vision: false,
        imageGeneration: false,
        search: false,
        trainingDataCutoff: 'Unknown',
        cost: 128,
        speed: 1,
        hidden: false
    },
    {
        id: 'o1-mini',
        versions: ['o1-mini'],
        name: 'o1 mini',
        logo: darkMode ? OpenAILogoLight : OpenAILogoDark,
        developer: 'OpenAI',
        description: 'OpenAI\'s lightweight reasoning model excelling at coding.',
        strength: 'Reasoning',
        contextWindow: '128k',
        parameters: '',
        multilingual: true,
        vision: false,
        imageGeneration: false,
        search: false,
        trainingDataCutoff: 'Unknown',
        cost: 28,
        speed: 1,
        hidden: false
    },
    {
        id: 'claude-3-opus-20240229',
        versions: ['claude-3-opus-20240229'],
        name: 'Claude Opus 3',
        logo: darkMode ? ClaudeLogoLight : ClaudeLogoDark,
        developer: 'Anthropic',
        description: 'Anthropic\'s powerful model for highly complex tasks',
        strength: 'Complex tasks',
        contextWindow: '200k',
        parameters: 'unknown',
        multilingual: true,
        vision: true,
        imageGeneration: false,
        search: false,
        trainingDataCutoff: 'August 2023',
        cost: 80,
        speed: 4,
        hidden: true
    },
    {
        id: 'claude-3-sonnet-20240229',
        versions: ['claude-3-sonnet-20240229'],
        name: 'Claude Sonnet 3',
        logo: darkMode ? ClaudeLogoLight : ClaudeLogoDark,
        developer: 'Anthropic',
        description: 'Anthropic\'s model balancing intelligence and speed',
        strength: 'Complex tasks',
        contextWindow: '200k',
        parameters: 'unknown',
        multilingual: true,
        vision: true,
        imageGeneration: false,
        search: false,
        trainingDataCutoff: 'August 2023',
        cost: 33,
        speed: 7,
        hidden: true
    },
    {
        id: 'claude-3-haiku-20240307',
        versions: ['claude-3-haiku-20240307'],
        name: 'Claude Haiku 3',
        logo: darkMode ? ClaudeLogoLight : ClaudeLogoDark,
        developer: 'Anthropic',
        description: 'Anthropic\'s fastest and most compact model for near-instant responsiveness',
        strength: 'Quick, simple tasks',
        contextWindow: '200k',
        parameters: 'unknown',
        multilingual: true,
        vision: true,
        imageGeneration: false,
        search: false,
        trainingDataCutoff: 'August 2023',
        cost: 4,
        speed: 9,
        hidden: true
    },
    {
        id: 'gpt-4o-mini',
        versions: ['gpt-4o-mini'],
        name: 'GPT-4o mini',
        logo: darkMode ? OpenAILogoLight : OpenAILogoDark,
        developer: 'OpenAI',
        description: 'OpenAI\'s affordable and intelligent small model for fast, lightweight tasks',
        strength: 'Quick, simple tasks',
        contextWindow: '128k',
        parameters: '175B parameters',
        multilingual: true,
        vision: true,
        imageGeneration: false,
        search: false,
        trainingDataCutoff: 'October 2023',
        cost: 4,
        speed: 9,
        hidden: false
    },
    {
        id: 'gpt-4-turbo',
        versions: ['gpt-4-turbo'],
        name: 'GPT-4 Turbo',
        logo: darkMode ? OpenAILogoLight : OpenAILogoDark,
        developer: 'OpenAI',
        description: 'OpenAI\'s latest GPT-4 Turbo model with vision capabilities',
        strength: 'Quick, simple tasks',
        contextWindow: '128k',
        parameters: '125B parameters',
        multilingual: true,
        vision: true,
        imageGeneration: false,
        search: false,
        trainingDataCutoff: 'October 2023',
        cost: 24,
        speed: 8,
        hidden: true
    },
    {
        id: 'gemini-1.5-pro',
        versions: ['gemini-1.5-pro'],
        name: 'Gemini 1.5 Pro',
        logo: GeminiLogo,
        developer: 'Google',
        description: 'Google\'s best performing multimodal model with features for a wide variety of reasoning tasks',
        strength: 'Complex tasks',
        contextWindow: 'unknown',
        parameters: 'unknown',
        multilingual: true,
        vision: true,
        imageGeneration: false,
        search: false,
        trainingDataCutoff: 'May 2024',
        cost: 13,
        speed: 7,
        hidden: false
    },
    {
        id: 'gemini-1.0-pro',
        versions: ['gemini-1.0-pro'],
        name: 'Gemini 1.0 Pro',
        logo: GeminiLogo,
        developer: 'Google',
        description: 'Google\'s legacy model - precursor to Gemini 1.5',
        strength: 'Complex tasks',
        contextWindow: 'unknown',
        parameters: 'unknown',
        multilingual: true,
        vision: true,
        imageGeneration: false,
        search: false,
        trainingDataCutoff: 'February 2024',
        cost: 13,
        speed: 6,
        hidden: true
    },
    {
        id: 'web-search',
        versions: ['web-search'],
        name: 'Searchy-1',
        logo: EllyLogo,
        developer: 'Elly',
        description: 'Our search model, powered by GPT-4o mini. Use this model only when you need up-to-date information from the internet.',
        strength: 'Web search',
        contextWindow: 'unknown',
        parameters: 'unknown',
        multilingual: false,
        vision: true,
        imageGeneration: false,
        search: true,
        trainingDataCutoff: 'Real-time web data',
        cost: 25,
        speed: 3,
        hidden: true
    },
    {
        id: 'mistral-small-latest',
        versions: ['mistral-small-latest'],
        name: 'Mistral Small',
        logo: MistralLogo,
        developer: 'Mistral',
        description: 'Mistral\'s small model, with a focus on fast, lightweight tasks.',
        strength: 'Quick, simple tasks',
        contextWindow: '32k',
        parameters: '22B parameters',
        multilingual: false,
        vision: false,
        imageGeneration: false,
        search: false,
        trainingDataCutoff: 'Unknown',
        cost: 4,
        speed: 8,
        hidden: false
    },
    {
        id: 'mistral-large-latest',
        versions: ['mistral-large-latest'],
        name: 'Mistral Large',
        logo: MistralLogo,
        developer: 'Mistral',
        description: 'Mistral\'s large model, with a focus on complex tasks.',
        strength: 'Complex tasks',
        contextWindow: '128k',
        parameters: 'unknown',
        multilingual: false,
        vision: false,
        imageGeneration: false,
        search: false,
        trainingDataCutoff: 'Unknown',
        cost: 16,
        speed: 8,
        hidden: false
    },
    {
        id: 'pixtral-12b-2409',
        versions: ['pixtral-12b-2409'],
        name: 'Pixtral 12B',
        logo: MistralLogo,
        developer: 'Mistral',
        description: 'Mistral\'s 12B model, with image understanding.',
        strength: 'Complex tasks',
        contextWindow: '128k',
        parameters: '12B parameters',
        multilingual: false,
        vision: true,
        imageGeneration: false,
        search: false,
        trainingDataCutoff: 'Unknown',
        cost: 16,
        speed: 8,
        hidden: true
    },
    {
        id: 'perplexica-general',
        versions: ['perplexica-general'],
        name: 'Perplexica General',
        logo: PerplexicaLogo,
        developer: 'Perplexica',
        description: 'Perplexica\'s general search model, powered by GPT-4o mini. Use for general up-to-date information from the internet.',
        strength: 'Web search',
        contextWindow: '128k',
        parameters: '175B parameters',
        multilingual: true,
        vision: false,
        imageGeneration: false,
        search: true,
        trainingDataCutoff: 'Real-time web data',
        cost: 13,
        speed: 1,
        hidden: false
    },
    {
        id: 'perplexica-academic',
        versions: ['perplexica-academic'],
        name: 'Perplexica Academic',
        logo: PerplexicaLogo,
        developer: 'Perplexica',
        description: 'Perplexica\'s academic search model, powered by GPT-4o mini. Searches through academic papers.',
        strength: 'Web search',
        contextWindow: '128k',
        parameters: '175B parameters',
        multilingual: true,
        vision: false,
        imageGeneration: false,
        search: true,
        trainingDataCutoff: 'Real-time web data',
        cost: 13,
        speed: 1,
        hidden: false
    }
];