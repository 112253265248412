import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

function SignUpButton({ width = 'w-40', inNavbar = false }) {
    const { loginWithRedirect } = useAuth0();

    const getButtonClass = () => {
        const baseClasses = `${width} px-2
            bg-primary dark:bg-primary
            text-white dark:text-white
            border border-primary dark:border-primary
            hover:bg-primary-hover dark:hover:bg-primary-hover
            transition-colors duration-300`;

        if (inNavbar) {
            // Navbar version - more compact, less rounded
            return `${baseClasses}
                py-2
                rounded-md`;
        } else {
            // Standard version - taller, more rounded, with shadow
            return `${baseClasses}
                py-2.5 text-base
                rounded-lg shadow-md`;
        }
    };

    const handleSignUp = () => {
        loginWithRedirect({
            authorizationParams: {
                screen_hint: 'signup'
            }
        });
    };

    return (
        <button 
            className={getButtonClass()}
            onClick={handleSignUp}
        >
            {inNavbar ? 'Sign up' : 'Get started for free'}
        </button>
    );
}

export default SignUpButton;