import React from 'react';

const DeleteAssistantPopup = ({ onClose, onDelete, assistant }) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-10 backdrop-blur-sm p-4" onClick={onClose}>
            <div className="bg-white dark:bg-gray-800 rounded-lg p-4 sm:p-6 w-full max-w-sm sm:max-w-md" onClick={e => e.stopPropagation()}>
                <h2 className="text-lg sm:text-xl font-bold mb-2 text-gray-900 dark:text-gray-100">
                    Are you sure?
                </h2>
                <p className="text-sm text-gray-600 dark:text-gray-400 mb-4">
                    This will delete the assistant "{assistant.decrypted_title}" and <span className="font-bold">cannot be undone</span>.
                </p>
                <div className="flex flex-col sm:flex-row justify-between space-y-2 sm:space-y-0 sm:space-x-2">
                    <button
                        onClick={onClose}
                        className="w-full sm:w-1/2 px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={onDelete}
                        className="w-full sm:w-1/2 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
                    >
                        Delete
                    </button>
                    
                </div>
            </div>
        </div>
    );
};

export default DeleteAssistantPopup;