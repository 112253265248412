import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { API_URL } from '../config/api';

let stripePromise;
if (process.env.NODE_ENV === 'production') {
    stripePromise = loadStripe("pk_live_51QKIP0Ks1rAz4DYGY7UbssGVBDYXWjLir18y2pwZcENDGEnuiwRa6gRrcaAMpcvLVIL2HHcHNSIahLMFUyZfMxlB006IjNcvLP");
} else {
    stripePromise = loadStripe("pk_test_51QKIP0Ks1rAz4DYG6eVCDk38HdJXkk2SCT79GUS4AJ7j3QUvkQMuHCHSv0iHCaAJCN4SqL4aycPlQxNJRtiCKxJ600DGv3sDks");
}

function CheckoutForm({ selectedPlan, setSelectedPlan }) {
    const { user, logout } = useAuth0();
    const [clientSecret, setClientSecret] = useState(null);

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const planFromUrl = urlParams.get('plan');
        
        if (planFromUrl) {
            setSelectedPlan(planFromUrl);
        }
    }, []);

    useEffect(() => {
        // Reset client secret when plan changes
        if (clientSecret) {
            // Get the stripe instance from the promise
            stripePromise.then(stripe => {
                if (stripe && stripe.embedded && stripe.embedded.checkout) {
                    stripe.embedded.checkout.unmount();
                }
            });
        }
        setClientSecret(null);
        
        // Fetch new client secret
        fetch(`${API_URL}/create-checkout-session`, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({  
            plan: selectedPlan,
            userId: user.sub
          }),
        })
        .then((res) => {
            if (!res.ok) {
              throw new Error(`HTTP error! status: ${res.status}`);
            }
            return res.json();
        })
        .then((data) => {
            if (!data.clientSecret) {
              throw new Error('No client secret received');
            }
            setClientSecret(data.clientSecret);
        })
        .catch((error) => {
            console.error('Error creating checkout session:', error);
        });

        // Cleanup function
        return () => {
            if (clientSecret) {
                stripePromise.then(stripe => {
                    if (stripe && stripe.embedded && stripe.embedded.checkout) {
                        stripe.embedded.checkout.unmount();
                    }
                });
            }
        };
    }, [selectedPlan, user.email]);

    return (
        <div className="min-h-screen w-full flex flex-col">

            <div id="checkout-form" className="relative isolate px-6 pt-14 lg:px-8 items-center justify-center">
                <div
                    aria-hidden="true"
                    className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                >
                    <div
                        style={{
                        clipPath:
                            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-primary/50 to-primary opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                    />
                </div>

                <div className="flex flex-col items-center justify-center">
                    <h1 className="text-4xl font-bold">Choose a subscription to access Elly</h1>
                    <hr className="my-4" />
                </div>

                <div className="flex flex-col items-center justify-center">
                    <p className="text-left text-lg mb-1">Signed in as {user.name} ({user.email})</p>
                    <p className="text-left text-sm mb-1"></p>
                    <p className="text-left text-sm mb-1">Not you? <a className="text-primary hover:cursor-pointer" onClick={() => logout({ returnTo: window.location.origin })}>Sign out</a></p>
                    <hr className="my-2" />
                </div>


                <div className="flex items-center justify-center gap-4 mb-8">
                    <span className={`text-lg font-medium ${selectedPlan === 'plus' ? 'text-primary' : 'text-muted-foreground'}`}>
                        Plus
                    </span>
                    <button
                        onClick={() => setSelectedPlan(selectedPlan === 'plus' ? 'pro' : 'plus')}
                        className={"relative inline-flex h-8 w-14 items-center rounded-full transition-colors bg-background"}
                    >
                        <span
                            className={`inline-block h-6 w-6 transform rounded-full bg-primary transition-transform ${
                                selectedPlan === 'pro' ? 'translate-x-7' : 'translate-x-1'
                            }`}
                        />
                    </button>
                    <span className={`text-lg font-medium ${selectedPlan === 'pro' ? 'text-primary' : 'text-muted-foreground'}`}>
                        Pro
                    </span>
                </div>

                <div id="checkout" className="w-2/3 max-w-6xl justify-center items-center p-4 mx-auto border border-primary rounded-2xl bg-background shadow-lg shadow-primary/50">
                    {clientSecret && (
                    <EmbeddedCheckoutProvider
                        key={clientSecret}
                        stripe={stripePromise}
                        options={{ clientSecret }}
                    >
                        <EmbeddedCheckout />
                    </EmbeddedCheckoutProvider>
                )}
                </div>
                <br className="my-6" />
                <div className="flex flex-col items-center justify-center">
                    <p className="text-left text-sm mb-1">If no checkout form appears, please refresh the page.</p>
                </div>
                <br className="my-6" />
            </div>
        </div>
    );
}

export default CheckoutForm;