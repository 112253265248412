import React, { useState, useEffect, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import Logo from './Logo';
import PromptOptions from './PromptOptions';
import Header from './Header';
import InputContainer from './InputContainer';
import SideBar from './SideBar';
import ChatArea from './ChatArea';
import WelcomeMessage from './WelcomeMessage';
import MagicMode from './MagicMode';
import FeatureAlert from './FeatureAlert';
import { API_URL } from '../config/api';
import { Bars3Icon } from '@heroicons/react/24/outline';
import ChatSearchPopup from './ChatSearchPopup';
import EditTitlePopup from './EditTitlePopup';
import ClearConversationPopup from './ClearConversationPopup';
import { formatMessages } from '../utils/messageUtils';
import AttachmentsPreview from './Attachments';

function AuthenticatedApp({ darkMode, toggleDarkMode }) {
    const { user, getAccessTokenSilently } = useAuth0();
    const chatEnd = useRef(null);

    // Initialize all auth-related state here
    const [messages, setMessages] = useState([]);
    const [firstMessageSent, setFirstMessageSent] = useState(false);
    const [selectedModel, setSelectedModel] = useState(null);
    const [streamedResponse, setStreamedResponse] = useState('');
    const [streaming, setStreaming] = useState(false);
    const [isMinimized, setIsMinimized] = useState(true);
    const [conversations, setConversations] = useState([]);
    const [conversationId, setConversationId] = useState(null);
    const [creditsBalance, setCreditsBalance] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [selectedAssistant, setSelectedAssistant] = useState(null);
    const [addSystemPrompt, setAddSystemPrompt] = useState(true);
    const [systemPrompt, setSystemPrompt] = useState('');
    const [magicMode, setMagicMode] = useState(true);
    const [magicModeSetting, setMagicModeSetting] = useState(true);
    const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
    const [showSearchPopup, setShowSearchPopup] = useState(false);
    const [editingConversation, setEditingConversation] = useState(null);
    const [starredConversations, setStarredConversations] = useState([]);
    const [nonStarredConversations, setNonStarredConversations] = useState([]);
    const [conversationPopup, setConversationPopup] = useState(null);
    const [clearingConversation, setClearingConversation] = useState(null);
    const [fileAttachments, setFileAttachments] = useState({
        uploadedImages: [],
        attachedPdfs: [],
        attachedDocx: [],
        attachedTxts: [],
        attachedCsvs: [],
        attachedXlsx: [],
        attachedJsons: []
    });

    const handleShowSearch = () => setShowSearchPopup(true);

    const fetchMessages = async (clickedConversationId) => {
        try {
            setConversationId(clickedConversationId);

            const response = await fetch(`${API_URL}/messages/${clickedConversationId}`);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            const formattedMessages = formatMessages(data);
            setMessages(formattedMessages);

            setSelectedModel(formattedMessages[formattedMessages.length - 1].model);
            setFirstMessageSent(true);
        } catch (error) {
            console.error('Error fetching conversation messages:', error);
        }
    };

    const updateConversationLists = (updatedConversation) => {
        setConversations(prevConversations => {
            const newConversations = prevConversations.map(conv => 
                conv.conversation_id === updatedConversation.conversation_id ? updatedConversation : conv
            );
            const starred = newConversations.filter(conv => conv.starred);
            const nonStarred = newConversations.filter(conv => !conv.starred);
            setStarredConversations(starred);
            setNonStarredConversations(nonStarred);
            return newConversations;
        });
    };

    const handleTitleSubmit = async (editedTitle, editedTitleConversationId) => {
        try {
            const response = await fetch(`${API_URL}/conversations/${editedTitleConversationId}/title`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ title: editedTitle }),
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const updatedConversation = await response.json();

            updateConversationLists(updatedConversation);
            toast.success('Conversation title updated');
    
        } catch (error) {
            console.error('Error updating conversation title:', error);
            toast.error('Oops, something went wrong. Please try again.');
        }

        setEditingConversation(null);
    };

    const handleEditTitle = (conversation) => {
        setEditingConversation(conversation);
    };

    const handleStarConversation = async (conversation) => {
        setConversationPopup(null);
        try {
            const response = await fetch(`${API_URL}/conversations/${conversation.conversation_id}/star`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const updatedConversation = await response.json();
            updateConversationLists(updatedConversation);

        } catch (error) {
            console.error('Error starring conversation:', error);
            toast.error('Oops, something went wrong. Please try again.');
        }
    };

    const handleClearConversation = (conversation) => {
        setClearingConversation(conversation);
        setConversationPopup(null);
    };

    const clearConversation = async () => {
        if (!clearingConversation) return;

        try {
            const response = await fetch(`${API_URL}/conversations/${clearingConversation.conversation_id}/clear`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ action: 'clear_messages' }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            setConversations(prevConversations => {
                const newConversations = prevConversations.filter(conv => conv.conversation_id !== clearingConversation.conversation_id);
                const starred = newConversations.filter(conv => conv.starred);
                const nonStarred = newConversations.filter(conv => !conv.starred);
                setStarredConversations(starred);
                setNonStarredConversations(nonStarred);
                return newConversations;
            });

            if (conversationId === clearingConversation.conversation_id) {
                setConversationId(null);
                setMessages([]);
                setFirstMessageSent(false);
            }

            toast.success('Conversation cleared');
        } catch (error) {
            console.error('Error clearing conversation:', error);
            toast.error('Oops, something went wrong. Please try again.');
        }

        setClearingConversation(null);
    };

    const handleRemoveFile = (stateKey, index) => {
        setFileAttachments(prev => ({
            ...prev,
            [stateKey]: prev[stateKey].filter((_, i) => i !== index)
        }));
    };

    useEffect(() => {
        if (chatEnd.current) {
            chatEnd.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    useEffect(() => {
        if (user) {
            const sendUserInfo = async () => {
                try {
                    const token = await getAccessTokenSilently();
                    const result = await fetch(`${API_URL}/user`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                        body: JSON.stringify({
                            sub: user.sub
                        })
                    });
                    const userInfoData = await result.json();
                    setAddSystemPrompt(userInfoData.settingsResult.add_system_prompt);
                    setSystemPrompt(userInfoData.settingsResult.decrypted_system_prompt);
                    setMagicMode(userInfoData.settingsResult.magic_mode);
                    setMagicModeSetting(userInfoData.settingsResult.magic_mode);
                } catch (error) {
                    console.error('Error sending user info:', error);
                }
            };
            sendUserInfo();
        }
    }, [user, getAccessTokenSilently]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 1024) { // 1024px is the 'lg' breakpoint in Tailwind
                setIsMobileSidebarOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);
        
        // Cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (selectedModel === 'o1-mini' || selectedModel === 'o1-preview') {
            toast.info(`${selectedModel} is an expensive advanced reasoning model. We recommend using it rarely`, {
                toastId: 'expensive-model-warning', // Prevents duplicate toasts
            });
        }
    }, [selectedModel]);

    return (
        <BrowserRouter>
            <div className="relative flex h-screen bg-background dark:bg-background-dark text-text dark:text-text-dark">
                <button 
                    className="lg:hidden fixed top-4 left-4 z-50 p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-800"
                    onClick={() => setIsMobileSidebarOpen(!isMobileSidebarOpen)}
                >
                    <Bars3Icon className="h-6 w-6 text-primary" />
                </button>
                
                <div className="relative z-50">
                    <SideBar 
                        isMinimized={isMinimized}
                        setIsMinimized={setIsMinimized}
                        isMobileSidebarOpen={isMobileSidebarOpen}
                        setIsMobileSidebarOpen={setIsMobileSidebarOpen}
                        setSelectedModel={setSelectedModel}
                        setMessages={setMessages}
                        firstMessageSent={firstMessageSent}
                        setFirstMessageSent={setFirstMessageSent}
                        user={user}
                        setConversations={setConversations}
                        conversationId={conversationId}
                        setConversationId={setConversationId}
                        onShowSearch={handleShowSearch}
                        onEditTitle={handleEditTitle}
                        starredConversations={starredConversations}
                        setStarredConversations={setStarredConversations}
                        nonStarredConversations={nonStarredConversations}
                        setNonStarredConversations={setNonStarredConversations}
                        onStarConversation={handleStarConversation}
                        conversationPopup={conversationPopup}
                        setConversationPopup={setConversationPopup}
                        onClearConversation={handleClearConversation}
                    />
                </div>
                <div className={`flex-1 flex flex-col ${isMinimized ? 'lg:ml-16' : 'lg:ml-60'} ml-0`}>
                    <Header 
                        className="h-[5vh]"
                        darkMode={darkMode}
                        toggleDarkMode={toggleDarkMode}
                        creditsBalance={creditsBalance}
                        setCreditsBalance={setCreditsBalance}
                        addSystemPrompt={addSystemPrompt}
                        setAddSystemPrompt={setAddSystemPrompt}
                        systemPrompt={systemPrompt}
                        setSystemPrompt={setSystemPrompt}
                    />
                    <div className="flex-1 flex flex-col min-h-0 mx-auto w-full">
                        {!firstMessageSent ? (
                            <>
                                <div className="flex justify-center items-center w-full h-full">
                                    <div className="flex flex-col items-center">
                                        <Logo size="large" />
                                        <WelcomeMessage user={user} />
                                        <MagicMode 
                                            magicMode={magicMode}
                                            setMagicMode={setMagicMode}
                                        />
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className="h-full overflow-y-auto">
                                <ChatArea
                                    user={user}
                                    selectedModel={selectedModel}
                                    messages={messages} 
                                    chatEnd={chatEnd}
                                    streamedResponse={streamedResponse}
                                    streaming={streaming}
                                    darkMode={darkMode}
                                />
                            </div>
                        )}
                        <div className="w-full h-auto flex items-end">
                            <PromptOptions 
                                selectedModel={selectedModel} 
                                setSelectedModel={setSelectedModel}
                                darkMode={darkMode}
                                user={user}
                                selectedTemplate={selectedTemplate}
                                setSelectedTemplate={setSelectedTemplate}
                                selectedAssistant={selectedAssistant}
                                setSelectedAssistant={setSelectedAssistant}
                                magicMode={magicMode}
                                firstMessageSent={firstMessageSent}
                            />
                        </div>
                        <AttachmentsPreview 
                            fileAttachments={fileAttachments}
                            onRemoveFile={handleRemoveFile}
                        />
                        <div className="w-full p-1 flex-shrink-0">
                            <InputContainer 
                                messages={messages} 
                                setMessages={setMessages}
                                firstMessageSent={firstMessageSent}
                                setFirstMessageSent={setFirstMessageSent}
                                selectedModel={selectedModel}
                                setSelectedModel={setSelectedModel}
                                setStreamedResponse={setStreamedResponse}
                                streaming={streaming}
                                setStreaming={setStreaming}
                                user={user}
                                darkMode={darkMode}
                                conversationId={conversationId}
                                setConversationId={setConversationId}
                                setConversations={setConversations}
                                setCreditsBalance={setCreditsBalance}
                                selectedTemplate={selectedTemplate}
                                setSelectedTemplate={setSelectedTemplate}
                                selectedAssistant={selectedAssistant}
                                magicMode={magicMode}
                                magicModeSetting={magicModeSetting}
                                setMagicModeSetting={setMagicModeSetting}
                                fileAttachments={fileAttachments}
                                setFileAttachments={setFileAttachments}
                            />
                        </div>
                    </div>
                </div>
                <FeatureAlert />
                {showSearchPopup && (
                    <ChatSearchPopup
                        onClose={() => setShowSearchPopup(false)}
                        onSelect={(id) => {fetchMessages(id) }}
                        user={user}
                        setConversations={setConversations}
                    />
                )}
                {editingConversation && (
                    <EditTitlePopup
                        conversation={editingConversation}
                        onClose={() => setEditingConversation(null)}
                        onSubmit={handleTitleSubmit}
                    />
                )}
                {clearingConversation && (
                    <ClearConversationPopup
                        onClose={() => setClearingConversation(null)}
                        onClear={clearConversation}
                    />
                )}
                <ToastContainer
                    position="top-right"
                    theme={darkMode ? 'dark' : 'light'}
                    toastClassName={`${darkMode ? 'border border-border-dark' : ''}`}
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={true}
                    closeOnClick={false}
                    rtl={false}
                    pauseOnFocusLoss={true}
                    draggable
                    pauseOnHover
                />
            </div>
        </BrowserRouter>
    );
}

export default AuthenticatedApp;