import React, { useState, useEffect } from "react";
import { API_URL } from '../config/api';
import ellyInterfaceDark from '../assets/elly-interface-dark.png';
import { PaperAirplaneIcon } from '@heroicons/react/24/outline'
import { useAuth0 } from '@auth0/auth0-react';

const CheckoutReturn = ({ setHasAccess, setPaymentComplete }) => {
  const [status, setStatus] = useState(null);
  const [customerName, setCustomerName] = useState('');
  const { user } = useAuth0();

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');

    if (!sessionId) {
      return;
    }

    fetch(`${API_URL}/session-status?session_id=${sessionId}`)
      .then((res) => res.json())
      .then((data) => {
        setStatus(data.status);
        setCustomerName(data.customer_name.split(' ')[0]);
        if (data.status === 'complete') {
          setPaymentComplete(true);
      }
      })
      .catch(error => {
        console.error('Error fetching session status:', error);
        setStatus('error');
    });
  }, [user, setPaymentComplete]);

  const handleGetStarted = () => {
    setHasAccess(true);
  };

  if (status === 'open') {
    return (
      <Navigate to="/" />
    )
  }

  if (status === 'complete') {
    return (

      <div className="min-h-screen w-full flex flex-col">
        <div id="checkout-return" className="relative isolate px-6 pt-14 lg:px-8 items-center justify-center">
          <div
              aria-hidden="true"
              className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          >
              <div
                  style={{
                  clipPath:
                      'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                  }}
                  className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-primary/50 to-primary opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
              />
          </div>

          <div className="flex flex-col items-center justify-center">
              <h1 className="text-4xl font-bold">You're all set, {customerName}! 🎉</h1>
              <hr className="my-4" />
              <p className="text-lg">To start using Elly, just press the button below.</p>
              <button 
                  className="px-2 py-2.5 mt-8 bg-primary dark:bg-primary text-base text-white dark:text-white border border-primary dark:border-primary hover:bg-primary-hover dark:hover:bg-primary-hover transition-colors duration-300  rounded-lg shadow-md"
                  onClick={handleGetStarted}
              >
                  Let's get started!
              </button>
              <p className="my-8 text-center text-base text-gray-600 dark:text-text-dark/70">
                    Any questions? {' '}
                    <a 
                        href="mailto:simon@ask-elly.co"
                        className="text-primary hover:text-primary/80 font-medium"
                    >
                        <span className="text-primary">
                            Just reach out
                            <PaperAirplaneIcon className="h-4 w-4 inline-block ml-1 mb-1" aria-hidden="true" />
                        </span>
                    </a>
              </p>
              <img src={ellyInterfaceDark} alt="Elly Interface" className="w-1/2 py-4" />
          </div>
        </div>
      </div>
    )
  }

  return null;
}

export default CheckoutReturn;