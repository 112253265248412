import React, { useEffect, useRef } from 'react';
import Logo from './Logo';
import AuthButton from './AuthButton';
import SignUpButton from './SignUpButton';
import { Link, useLocation } from 'react-router-dom';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon, MoonIcon, SunIcon } from '@heroicons/react/24/outline';

export default function Header({ darkMode, toggleDarkMode }) {

    const location = useLocation();
    const isRootRoute = location.pathname === '/';

    const panelRef = useRef(null);
  
    const navigation = [
        { name: 'Product', sectionId: 'product' },
        { name: 'Pricing', sectionId: 'pricing' }
    ];

    const scrollToSection = (sectionId) => {
        const element = document.getElementById(sectionId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <header className="absolute inset-x-0 top-0 z-50">
            <Disclosure as="nav">
                {({ open, close }) => (
                    <>
                        {useEffect(() => {
                            function handleClickOutside(event) {
                                if (panelRef.current && 
                                    !panelRef.current.contains(event.target) && 
                                    open) {
                                    close();
                                }
                            }

                            document.addEventListener('mousedown', handleClickOutside);
                            return () => {
                                document.removeEventListener('mousedown', handleClickOutside);
                            };
                        }, [open, close])}

                        <div className="flex items-center justify-between p-6 lg:px-8">
                            {/* Logo section */}
                            <div className="flex h-10 items-center lg:flex-1">
                                {isRootRoute ? (
                                    <button
                                        onClick={() => scrollToSection('hero')}
                                        className="flex items-center space-x-2 hover:opacity-80 transition-opacity"
                                    >
                                        <Logo size="medium" />
                                        <span className="text-2xl font-bold text-text dark:text-text-dark">Elly</span>
                                    </button>
                                ) : (
                                    <Link to="/" className="flex items-center space-x-2 hover:opacity-80 transition-opacity">
                                        <Logo size="medium" />
                                        <span className="text-2xl font-bold text-text dark:text-text-dark">Elly</span>
                                    </Link>
                                )}
                            </div>

                            {/* Navigation */}
                            {isRootRoute && (
                                <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 hidden md:flex gap-x-4 md:gap-x-8 lg:gap-x-12 border border-gray-200 bg-background bg-opacity-95 dark:bg-secondary-dark dark:bg-opacity-95 dark:border-gray-500 rounded-full shadow-md px-6 py-2">
                                    {navigation.map((item) => (
                                        <button
                                            key={item.name}
                                            onClick={() => scrollToSection(item.sectionId)}
                                            className="text-sm text-gray-900 dark:text-gray-100 hover:text-primary dark:hover:text-primary"
                                        >
                                            {item.name}
                                        </button>
                                    ))}
                                </div>
                            )}

                            {/* Right section */}
                            <div className="flex h-10 items-center gap-2">
                                <button
                                    onClick={toggleDarkMode}
                                    className="h-10 w-10 flex items-center justify-center rounded-full hover:bg-gray-200 dark:hover:bg-gray-700"
                                >
                                    {darkMode ? (
                                        <SunIcon className="h-5 w-5" />
                                    ) : (
                                        <MoonIcon className="h-5 w-5 text-gray-500" />
                                    )}
                                </button>
                                
                                <div className="hidden md:flex items-center gap-2">
                                    <AuthButton width="w-auto sm:w-20" />
                                    <SignUpButton width="w-auto sm:w-20" inNavbar={true} />
                                </div>

                                {/* Mobile menu button */}
                                <div className="md:hidden">
                                    <DisclosureButton className="h-10 w-10 flex items-center justify-center rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 border border-border-border dark:border-border-dark">
                                        <span className="sr-only">Open menu</span>
                                        {open ? (
                                            <XMarkIcon className="h-6 w-6" />
                                        ) : (
                                            <Bars3Icon className="h-6 w-6" />
                                        )}
                                    </DisclosureButton>
                                </div>
                            </div>
                        </div>

                        {/* Mobile menu panel */}
                        <DisclosurePanel className="md:hidden" ref={panelRef}>
                        <div className="mx-4 mt-2 mb-4 rounded-lg bg-white dark:bg-gray-800 shadow-lg border border-gray-200 dark:border-gray-700 overflow-hidden">
                            {/* Navigation section */}
                            {isRootRoute && (
                                <div className="p-4 border-b border-gray-200 dark:border-gray-700">
                                    <div className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-2">
                                        Learn more
                                    </div>
                                    <div className="flex gap-2">
                                        {navigation.map((item) => (
                                            <DisclosureButton
                                                key={item.name}
                                                as="button"
                                                onClick={() => scrollToSection(item.sectionId)}
                                                className="flex-1 text-center px-3 py-2 rounded-md text-base font-medium bg-background dark:bg-background-dark text-gray-900 dark:text-gray-100 hover:bg-gray-100 dark:hover:bg-gray-700 border border-gray-200 dark:border-gray-700"
                                            >
                                                {item.name}
                                            </DisclosureButton>
                                        ))}
                                    </div>
                                </div>
                            )}
                            
                            {/* Auth section */}
                            <div className="p-4">
                                <div className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-2">
                                    Account
                                </div>
                                <div className="flex gap-2">
                                    <DisclosureButton as="div" className="w-full">
                                        <AuthButton width="w-full" />
                                    </DisclosureButton>
                                    <DisclosureButton as="div" className="w-full">
                                        <SignUpButton width="w-full" inNavbar={true} />
                                    </DisclosureButton>
                                </div>
                            </div>
                        </div>
                    </DisclosurePanel>
                    </>
                )}
            </Disclosure>
        </header>
    );
}