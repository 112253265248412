import React from 'react';
import SignUpButton from './SignUpButton';
import { ChevronDownIcon, ChevronUpIcon, CheckIcon } from '@heroicons/react/20/solid'
import { PaperAirplaneIcon } from '@heroicons/react/24/outline'
import ellyInterfaceDark from '../assets/elly-interface-dark.png';
import ellyInterfaceLight from '../assets/elly-interface-light.png';
import ellyModelsLight from '../assets/elly-models-light.png';
import ellyModelsDark from '../assets/elly-models-dark.png';
import ellyCarbonRemoval from '../assets/elly-carbon-removal.png';
import ellyMagicModeDark from '../assets/elly-magic-mode-dark.png';
import ellyMagicModeLight from '../assets/elly-magic-mode-light.png';
import ellyPrivacyDark from '../assets/elly-privacy-dark.png';
import ellyPrivacyLight from '../assets/elly-privacy-light.png';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { useAuth0 } from '@auth0/auth0-react';

const tiers = [
    {
        name: 'Plus',
        id: 'plus',
        priceMonthly: '€13',
        description: "The perfect plan for most users. We recommend starting here.",
        features: [
            'All models, incl. GPT-4o & Sonnet 3.5', 
            'Upload images and docs',
            'Access to Magic Mode, and other features',
            '€1.3 per month invested in carbon removal'
        ],
        featured: true,
    },
    {
        name: 'Pro',
        id: 'pro',
        priceMonthly: '€20',
        description: "Choose this Pro if the usage limits on Plus are too restrictive",
        features: [
            'Everything in Plus',
            'Significantly more usage than in Plus',
            '€2 per month invested in carbon removal'
        ],
        featured: false,
    },
]

const faqs = [
    {
        question: "Why are LLMs challenging for the environment?",
        answer: "LLMs are very energy-intensive. They consume large amounts of electricity, which in turn causes greenhouse gas emissions. Additionally, LLMs also consume large amounts of water, required for cooling of servers in datacenters."
    },
    {
        question: "How does Elly care about the environment?",
        answer: "At Elly, we are on a mission to help as many people as possible unlock the power of AI while being mindful of the environment. Our Magic Mode prioritizes less energy-intensive models in its recommendations. In addition, we invest a portion of our profits into verified carbon removal projects."
    },
    {
        question: "How much carbon do I save by using Elly?",
        answer: "There's no way of knowing the specific numbers - unfortunately. The companies that build the LLMs don't publish their energy consumption or their emissions. But the problem is very real and we believe it's too important to ignore. That's why we've built Elly."
    },
    {
        question: "Why don't you offer a free tier?",
        answer: "'If you're not paying for the product, you are the product' is a common saying in the tech industry. This often happens through targeted adversiting. Now, at Elly, we're against selling adds. Therefore, we don't offer a free tier. Instead, we offer a 2-week trial on our Plus plan so you can experience Elly before paying anything."
    },
    {
        question: "How does the Magic Mode work?",
        answer: "When you send a prompt with Magic Mode turned on, Elly will read your prompt, and recommend which model to use based both on what the different models are good at and how energy-intensive they are."
    },
    {
        question: "How does Elly protect my privacy?",
        answer: "We do that in several ways: (A) We encrypt all your conversations, which means that we don't read your messages. (B) We anonymize your prompts before sending them to the LLMs. (C) Your data is not used to train new LLMs. (D) We don't sell your data to third parties, e.g., for advertising purposes."
    },
    {
        question: "What is carbon removal?",
        answer: "Carbon removal is the process of removing carbon dioxide from the atmosphere. This can take many forms, such as reforestation, direct air capture, and biochar. We invest only in projects that have been rigorously analyzed and verified by third parties."
    },
    {
        question: "Who are the team behind Elly?",
        answer: "Elly was founded in Denmark by Elsa and Simon. If you're interested in learning more about us, potentially even working with us, just get in touch."
    }
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function LandingPage({ darkMode, setSelectedPlan }) {
    const { loginWithRedirect } = useAuth0();

    const scrollToSection = (sectionId) => {
        const element = document.getElementById(sectionId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const getStartedClicked = (plan) => {
        setSelectedPlan(plan);
        loginWithRedirect({
            appState: { returnTo: `/checkout?plan=${plan}` },
            authorizationParams: {
                screen_hint: 'signup'
            }
        });
    };

    return (
        <div className="min-h-screen w-full flex flex-col">

            {/* Hero section */}
            <div id="hero" className="relative isolate px-6 pt-14 lg:px-8">
                <div
                    aria-hidden="true"
                    className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                >
                    <div
                        style={{
                        clipPath:
                            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-primary/50 to-primary opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                    />
                </div>
                <div className="mx-auto max-w-4xl py-24 sm:py-32 lg:pt-40 lg:pb-36">
                    <div className="flex mb-8 justify-center">
                        <div className="relative rounded-full px-3 py-1 text-sm/6 text-gray-600 dark:text-text-dark ring-1 ring-gray-900/10 dark:ring-1 dark:ring-gray-400/10 flex items-center gap-2 bg-background dark:bg-secondary-dark bg-opacity-70 dark:bg-opacity-70 shadow-sm dark:shadow-sm">
                            <span className="inline-block w-3 h-3 bg-primary rounded-full animate-pulse" />
                            Live in beta
                        </div>
                    </div>
                    <div className="text-center">
                        <h1 className="text-balance text-3xl font-semibold tracking-tight text-text dark:text-text-dark sm:text-4xl md:text-5xl lg:text-6xl">
                            The AI friend built with planet and privacy in mind
                        </h1>
                        <p className="mt-8 text-pretty text-lg font-medium text-gray-500 dark:text-text-dark/70 sm:text-xl/8">
                            Elly is a planet and privacy-conscious friend you can chat with, built on top of the world's best AI models. Improving every week. Get started for free.
                        </p>
                        <div className="mt-10 flex flex-col sm:flex-row items-center justify-center gap-4">
                            <SignUpButton width="w-44" inNavbar={false} />
                            <button
                                onClick={() => scrollToSection('product')}
                                className="w-auto text-text dark:text-white rounded-md hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-300 p-3 text-md flex items-center justify-center gap-2"
                            >
                                Learn more <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="flex items-center justify-center">
                    <img
                        alt="Elly interface"
                        src={darkMode ? ellyInterfaceDark : ellyInterfaceLight}
                        width={2432}
                        height={1442}
                        className="w-full max-w-[90vw] md:max-w-5xl rounded-lg shadow-xl ring-1 ring-gray-400/10 md:-ml-4 lg:-ml-0"
                    />
                </div>
                <div
                    aria-hidden="true"
                    className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                >
                <div
                    style={{
                    clipPath:
                        'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                    }}
                    className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-primary to-primary/50 opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                />
                </div>
            </div>

            {/* Product section */}
            <div id="product" className="py-24 sm:py-32 lg:pt-40 lg:pb-32">
                <div className="mx-auto max-w-5xl px-6 lg:max-w-7xl lg:px-8">
                    <h2 className="text-center text-base/7 font-semibold text-primary">What makes Elly special</h2>
                    <p className="mx-auto mt-2 max-w-5xl text-balance text-center text-4xl font-semibold tracking-tight text-text dark:text-text-dark sm:text-5xl">
                        The experience you know, but optimized for planet and privacy
                    </p>
                    <p className="mx-auto mt-6 max-w-4xl text-pretty text-center text-lg font-medium text-text/70 dark:text-text-dark/70 sm:text-xl/8">
                        Enjoy the features you're used to, such as fast responses and uploading images and documents - and then some things that make Elly special
                    </p>
                    <div className="mt-10 grid gap-4 sm:mt-16 lg:grid-cols-3 lg:grid-rows-2">
                    <div className="relative lg:row-span-2">
                        <div className="absolute inset-px rounded-lg bg-white/50 dark:bg-background-dark lg:rounded-l-[2rem]"></div>
                        <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] lg:rounded-l-[calc(2rem+1px)]">
                            <div className="px-8 pb-3 pt-8 sm:px-10 sm:pb-0 sm:pt-10">
                                <p className="mt-2 text-lg font-medium tracking-tight text-text dark:text-text-dark max-lg:text-center">
                                    Leading AI models, gathered here
                                </p>
                                <p className="mt-2 max-w-lg text-sm/6 text-text/70 dark:text-text-dark/70 max-lg:text-center">
                                    Elly connects to +10 of the best AI models, including from OpenAI, Anthropic and Google - and more are on the way. Combine and switch between them as you'd like
                                </p>
                            </div>
                            <div className="relative min-h-[30rem] w-full grow [container-type:inline-size] max-lg:mx-auto max-lg:max-w-sm">
                                <div className="absolute inset-x-10 bottom-0 top-10 overflow-hidden rounded-t-[12cqw] border-x-[2cqw] border-t-[2cqw] border-gray-700 bg-gray-900 dark:bg-background-dark shadow-2xl">
                                <img
                                    className="size-full object-cover object-top"
                                    src={darkMode ? ellyModelsDark : ellyModelsLight}
                                    alt=""
                                />
                                </div>
                            </div>
                        </div>
                        <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 lg:rounded-l-[2rem]"></div>
                    </div>
                    <div className="relative max-lg:row-start-1">
                        <div className="absolute inset-px rounded-lg bg-white/50 dark:bg-background-dark max-lg:rounded-t-[2rem]"></div>
                        <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-t-[calc(2rem+1px)]">
                            <div className="px-8 pt-8 sm:px-10 sm:pt-10">
                                <p className="mt-2 text-lg font-medium tracking-tight text-text dark:text-text-dark max-lg:text-center">Privacy protecting</p>
                                <p className="mt-2 max-w-lg text-sm/6 text-text/70 dark:text-text-dark/70 max-lg:text-center">
                                    Your chats are for your eyes only. They are encrypted, anonymized, and not sold to third parties. We won't target you with ads.
                                </p>
                            </div>
                            <div className="flex flex-1 items-center justify-center max-lg:pb-12 max-lg:pt-10 w-full">
                                <img
                                className="w-full"
                                src={darkMode ? ellyPrivacyDark : ellyPrivacyLight}
                                alt=""
                                />
                            </div>
                        </div>
                    <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-t-[2rem]"></div>
                    </div>
                    <div className="relative max-lg:row-start-3 lg:col-start-2 lg:row-start-2">
                        <div className="absolute inset-px rounded-lg bg-white/50 dark:bg-background-dark max-lg:rounded-b-[2rem]"></div>
                        <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1pChoosex)]">
                            <div className="px-8 pt-8 sm:px-10 sm:pt-10">
                                <p className="mt-2 text-lg font-medium tracking-tight text-text dark:text-text-dark max-lg:text-center">Committed to carbon removal</p>
                                <p className="mt-2 max-w-lg text-sm/6 text-text/70 dark:text-text-dark/70 max-lg:text-center">
                                    We invest 10% of our revenue in verified {''} 
                                    <button 
                                        onClick={() => scrollToSection('faq')}
                                        className="text-primary hover:text-primary/80"
                                    >
                                        carbon removal
                                    </button>
                                    {' '}projects.
                                </p>
                            </div>
                            <div className="flex flex-1 items-center justify-center [container-type:inline-size] max-lg:py-6 lg:pb-2">
                                <img
                                className="w-5/6 object-cover"
                                src={ellyCarbonRemoval}
                                alt="Chart showing Elly invests 10% of revenue into carbon removal projects"
                                />
                            </div>
                        </div>
                        <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5"></div>
                    </div>
                    <div className="relative lg:row-span-2">
                        <div className="absolute inset-px rounded-lg bg-white/50 dark:bg-background-dark max-lg:rounded-b-[2rem] lg:rounded-r-[2rem]"></div>
                        <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-b-[calc(2rem+1px)] lg:rounded-r-[calc(2rem+1px)]">
                            <div className="px-8 pb-3 pt-8 sm:px-10 sm:pb-0 sm:pt-10">
                                <p className="mt-2 text-lg font-medium tracking-tight text-text dark:text-text-dark max-lg:text-center">Magical model selection</p>
                                <p className="mt-2 max-w-lg text-sm/6 text-text/70 dark:text-text-dark/70 max-lg:text-center">
                                    With Magic Mode, Elly can analyse your prompt and recommend which model to use based on the models' characteristics (e.g., strengths, estimated energy intensity)
                                </p>
                            </div>
                            <div className="flex flex-1 items-center justify-center [container-type:inline-size] max-lg:py-6 lg:pb-2">
                                <img
                                className="w-4/6 object-cover"
                                src={darkMode ? ellyMagicModeDark : ellyMagicModeLight}
                                alt="Elly's Magic Mode"
                                />
                            </div>
                        </div>
                        <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-b-[2rem] lg:rounded-r-[2rem]"></div>
                    </div>
                    </div>
                </div>
            </div>


            {/* Pricing section */}
            <div id="pricing" className="relative isolate px-6 py-24 sm:py-24 lg:px-8">
                <div aria-hidden="true" className="absolute inset-x-0 -top-3 -z-10 transform-gpu overflow-hidden px-36 blur-3xl">
                    <div
                        style={{
                            clipPath:
                            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                        className="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-primary/50 to-primary opacity-30"
                    />
                </div>
                <div className="mx-auto max-w-4xl text-center">
                    <h2 className="text-base/7 font-semibold text-primary">Pricing</h2>
                    <p className="mx-auto mt-2 max-w-5xl text-balance text-center text-4xl font-semibold tracking-tight text-text dark:text-text-dark sm:text-5xl">
                        Get started for free. Cancel anytime.
                    </p>
                </div>
                <p className="mx-auto mt-6 max-w-4xl text-pretty text-center text-lg font-medium text-text/70 dark:text-text-dark/70 sm:text-xl/8">
                    Elly Plus is ideal for most users, and it's significantly more affordable than comparable 'Plus' plans. To help you figure out if Elly is for you, the first two weeks are free.
                </p>
                <div className="mx-auto mt-16 grid max-w-lg grid-cols-1 items-center gap-y-6 sm:mt-20 sm:gap-y-0 lg:max-w-4xl lg:grid-cols-2">
                    {tiers.map((tier, tierIdx) => (
                    <div
                        key={tier.id}
                        className={classNames(
                        tier.featured ? 'relative shadow-2xl border border-primary dark:border-primary' : 'sm:mx-8 lg:mx-0',
                        tier.featured
                            ? ''
                            : tierIdx === 0
                            ? 'rounded-t-3xl sm:rounded-b-none lg:rounded-bl-3xl lg:rounded-tr-none'
                            : 'sm:rounded-t-none lg:rounded-bl-none lg:rounded-tr-3xl',
                        'bg-background dark:bg-background-dark rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10',
                        )}
                    >
                        <h3
                            id={tier.id}
                            className={classNames('text-primary text-base/7 font-semibold')}
                        >
                            {tier.name}
                        </h3>
                        <p className="mt-4 flex items-baseline gap-x-2">
                            <span
                                className={classNames('text-text dark:text-text-dark text-5xl font-semibold tracking-tight')}
                            >
                                {tier.priceMonthly}
                            </span>
                            <span className={classNames('text-gray-500 text-base')}>/month (incl. VAT)</span>
                        </p>
                            <p className={classNames('text-gray-600 dark:text-text-dark mt-6 text-base/7')}>
                            {tier.description}
                        </p>
                        <ul
                            role="list"
                            className={classNames(
                                'mt-8 space-y-3 text-sm/6 sm:mt-10',
                            )}
                        >
                        {tier.features.map((feature) => (
                            <li key={feature} className="flex gap-x-3">
                                <CheckIcon
                                    aria-hidden="true"
                                    className={classNames('text-primary h-6 w-5 flex-none')}
                                />
                                {feature}
                            </li>
                        ))}
                        </ul>
                        <a
                            // href={tier.href}
                            onClick={() => getStartedClicked(tier.id)}
                            aria-describedby={tier.id}
                            className={classNames(
                                tier.featured
                                ? 'bg-primary text-white shadow-sm hover:bg-primary/80 focus-visible:outline-primary hover:cursor-pointer'
                                : 'text-primary ring-1 ring-inset ring-primary hover:ring-primary/80 focus-visible:outline-primary hover:cursor-pointer',
                                'mt-8 block rounded-md px-3.5 py-2.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:mt-10',
                            )}
                            >
                            Get started for free
                        </a>
                    </div>
                    ))}
                </div>

                <p className="mt-8 text-center text-base text-gray-600 dark:text-text-dark/70">
                    Looking for a plan for your business or team?{' '}
                    <a 
                        href="mailto:simon@ask-elly.co"
                        className="text-primary hover:text-primary/80 font-medium"
                    >
                        <span className="text-primary">
                            Get in touch
                            <PaperAirplaneIcon className="h-4 w-4 inline-block ml-1 mb-1" aria-hidden="true" />
                        </span>
                    </a>
                </p>
            </div>

            {/* FAQ section */}
            <div id="faq" className="mx-auto max-w-4xl px-6 py-24 sm:py-32 lg:px-8 w-full">
                <div className="mx-auto max-w-4xl">
                    <div className="mx-auto max-w-4xl text-center">
                        <h2 className="text-base/7 font-semibold text-primary">FAQs</h2>
                        <p className="mt-2 text-balance text-5xl font-semibold tracking-tight text-text dark:text-text-dark sm:text-6xl">
                            Questions?
                        </p>
                    </div>
                    <dl className="mt-10 space-y-6 divide-y divide-gray-900/10 dark:divide-gray-700">
                        {faqs.map((faq) => (
                            <Disclosure as="div" key={faq.question} className="pt-6">
                                {({ open }) => (
                                    <>
                                        <dt>
                                            <DisclosureButton className="flex w-full items-start justify-between text-left">
                                                <span className="text-base font-semibold leading-7 text-text dark:text-text-dark">
                                                    {faq.question}
                                                </span>
                                                <span className="ml-6 flex h-7 items-center">
                                                    {open ? (
                                                        <ChevronUpIcon className="h-6 w-6 text-primary" aria-hidden="true" />
                                                    ) : (
                                                        <ChevronDownIcon className="h-6 w-6 text-primary" aria-hidden="true" />
                                                    )}
                                                </span>
                                            </DisclosureButton>
                                        </dt>
                                        <DisclosurePanel as="dd" className="mt-2 pr-12">
                                            <p className="text-base leading-7 text-gray-600 dark:text-text-dark/70">
                                                {faq.answer}
                                            </p>
                                        </DisclosurePanel>
                                    </>
                                )}
                            </Disclosure>
                        ))}
                    </dl>
                </div>
                <p className="mt-8 text-center text-base text-gray-600 dark:text-text-dark/70">
                    Something else on your mind? {' '}
                    <a 
                        href="mailto:simon@ask-elly.co"
                        className="text-primary hover:text-primary/80 font-medium"
                    >
                        <span className="text-primary">
                            Just reach out
                            <PaperAirplaneIcon className="h-4 w-4 inline-block ml-1 mb-1" aria-hidden="true" />
                        </span>
                    </a>
                </p>
            </div>
        </div>
    );
}

export default LandingPage;