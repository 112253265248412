import React, { useState, useEffect, useRef } from 'react';
import { getModelInfo } from './ModelData';

const DesignTemplatePopup = ({ onClose, onSubmit, editTemplate = null }) => {
    const [templateTitle, setTemplateTitle] = useState(editTemplate ? editTemplate.decrypted_title : '');
    const [templatePrompt, setTemplatePrompt] = useState(editTemplate ? editTemplate.decrypted_prompt : '');
    const titleInputRef = useRef(null);
    const categoryOptions = [
        { name: 'Green', value: '#7cb342' },
        { name: 'Blue', value: '#90caf9' },
        { name: 'Red', value: '#ef9a9a' },
        { name: 'Yellow', value: '#fff59d' },
        { name: 'Purple', value: '#ce93d8' },
        { name: 'Orange', value: '#ffcc80' },
        { name: 'Teal', value: '#80cbc4' }
    ];
    
    const [templateCategory, setTemplateCategory] = useState(editTemplate ? editTemplate.category : categoryOptions[0].name);
    const [templateModel, setTemplateModel] = useState(editTemplate ? editTemplate.model : 'No preference');

    useEffect(() => {
        titleInputRef.current?.focus();
    }, []);

    const modelOptions = [
        { id: 'No preference', name: 'No preference' },
        ...getModelInfo()
            .filter(model => !model.hidden)
            .map(model => ({ id: model.id, name: model.name }))
    ];

    const handleSubmit = (e) => {
        e.preventDefault();
        if (templateTitle && templatePrompt && templateCategory) {
            onSubmit( templateTitle, templatePrompt, templateCategory, templateModel, editTemplate ? editTemplate.template_id : null);
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-10 backdrop-blur-sm p-4" onClick={onClose}>
            <div className="bg-white dark:bg-gray-800 rounded-lg p-4 sm:p-6 w-full max-w-sm sm:max-w-xl" onClick={e => e.stopPropagation()}>
                <h2 className="text-lg sm:text-xl font-bold mb-4 text-text dark:text-text-dark">
                    {editTemplate ? 'Edit your template' : 'Design your template'}
                </h2>
                <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
                    <input
                        ref={titleInputRef}
                        type="text"
                        value={templateTitle}
                        onChange={(e) => setTemplateTitle(e.target.value)}
                        placeholder="Give your template a name"
                        className="w-full px-3 py-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:outline-none focus:ring-2 focus:ring-primary lg:focus:ring-0"
                    />
                    <textarea
                        value={templatePrompt}
                        onChange={(e) => setTemplatePrompt(e.target.value)}
                        placeholder="Write your full prompt here"
                        className="w-full px-3 py-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:outline-none focus:ring-2 focus:ring-primary lg:focus:ring-0 h-52"
                    />
                    {/* Category selection */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                            Categorize it with a color:
                        </label>
                        <div className="flex flex-wrap gap-2">
                            {categoryOptions.map((category) => (
                                <button
                                    key={category.name}
                                    type="button"
                                    onClick={() => setTemplateCategory(category.name)}
                                    className={`w-6 h-6 rounded-lg ${
                                        templateCategory === category.name ? 'ring-1 ring-offset-2 ring-primary dark:ring-white' : ''
                                    }`}
                                    style={{ backgroundColor: category.value }}
                                    title={category.name}
                                />
                            ))}
                        </div>
                    </div>

                    {/* Model selection */}
                    <div>
                        <label htmlFor="model-select" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                            Any preferred model?
                        </label>
                        <select
                            id="model-select"
                            value={templateModel}
                            onChange={(e) => setTemplateModel(e.target.value)}
                            className="w-full px-3 py-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:outline-none focus:ring-2 focus:ring-primary lg:focus:ring-0"
                        >
                            {modelOptions.map((model) => (
                                <option key={model.id} value={model.id}>
                                    {model.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="flex flex-col sm:flex-row justify-between space-y-2 sm:space-y-0 sm:space-x-2">
                        <button
                            type="button"
                            onClick={onClose}
                            className="w-full sm:w-1/2 px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            disabled={!templateTitle || !templatePrompt || !templateCategory}
                            className="w-full sm:w-1/2 px-4 py-2 bg-primary text-white rounded hover:bg-primary-hover disabled:bg-gray-300 disabled:text-gray-500 transition-colors"
                        >
                            {editTemplate ? 'Update Template' : 'Create Template'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default DesignTemplatePopup;