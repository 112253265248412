import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import { askAi } from '../chatService';
import { EditorState, Plugin, TextSelection } from 'prosemirror-state'
import { EditorView, Decoration, DecorationSet } from 'prosemirror-view'
import { Schema } from 'prosemirror-model'
import { schema as basicSchema } from 'prosemirror-schema-basic'
import { keymap } from 'prosemirror-keymap'
import { toggleMark, baseKeymap } from 'prosemirror-commands'
import { history, undo, redo } from 'prosemirror-history'
import { addListNodes, wrapInList, splitListItem, liftListItem, sinkListItem } from 'prosemirror-schema-list'
import { inputRules, wrappingInputRule } from 'prosemirror-inputrules'
import Tooltip from './Tooltip'
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';
import mammoth from 'mammoth';
import Papa from 'papaparse';
import { getModelInfo } from './ModelData';
import { API_URL } from '../config/api';

GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.6.82/pdf.worker.mjs`;

const mySchema = new Schema({
    nodes: addListNodes(basicSchema.spec.nodes, 'paragraph block*', 'block'),
    marks: basicSchema.spec.marks
});

const unorderedListRule = wrappingInputRule(/^\s*([-*])\s$/, mySchema.nodes.bullet_list);
const orderedListRule = wrappingInputRule(/^\s*(\d+)\.\s$/, mySchema.nodes.ordered_list);

const InputContainer = ({ 
    messages, 
    setMessages, 
    firstMessageSent, 
    setFirstMessageSent, 
    selectedModel, 
    setSelectedModel,
    setStreamedResponse, 
    streaming, 
    setStreaming, 
    user, 
    darkMode, 
    conversationId, 
    setConversationId, 
    setConversations, 
    setCreditsBalance,
    selectedTemplate,
    setSelectedTemplate,
    selectedAssistant,
    magicMode,
    magicModeSetting,
    setMagicModeSetting,
    fileAttachments,
    setFileAttachments
}) => {
    const [input, setInput] = useState('');
    const [isDragging, setIsDragging] = useState(false);
    const dragCounter = useRef(0);
    const editorViewRef = useRef(null);
    const editorRef = useRef(null);
    const submitButtonRef = useRef(null);

    const modelInfo = getModelInfo();

    // File upload config
    const FILE_CONFIGS = {
        image: {
            max: 5,
            maxSize: 5 * 1024 * 1024,
            types: ['image/jpeg', 'image/png', 'image/webp'],
            stateKey: 'uploadedImages',
            displayName: 'Image',
            preview: true,
            processFile: async (file) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.onerror = reject;
                    reader.readAsDataURL(file);
                });
            }
        },
        pdf: {
            max: 5,
            maxSize: 30 * 1024 * 1024,
            types: ['application/pdf'],
            stateKey: 'attachedPdfs',
            displayName: 'PDF',
            preview: false,
            processFile: async (file) => {
                const pdfData = await file.arrayBuffer();
                const pdfDocument = await getDocument({ data: pdfData }).promise;
                let pdfText = '';
                for (let i = 1; i <= pdfDocument.numPages; i++) {
                    const page = await pdfDocument.getPage(i);
                    const content = await page.getTextContent();
                    pdfText += content.items.map(item => item.str).join(' ') + '\n';
                }
                return {
                    fileName: file.name,
                    content: `PDF: ${file.name}: ${pdfText}`
                };
            }
        },
        docx: {
            max: 5,
            maxSize: 30 * 1024 * 1024,
            types: ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
            stateKey: 'attachedDocx',
            displayName: 'DOCX',
            preview: false,
            processFile: async (file) => {
                const arrayBuffer = await file.arrayBuffer();
                const result = await mammoth.extractRawText({ arrayBuffer });
                return {
                    fileName: file.name,
                    content: `DOCX: ${file.name}: ${result.value}`
                };
                
            }
        },
        txt: {
            max: 5,
            maxSize: 30 * 1024 * 1024,
            types: ['text/plain'],
            stateKey: 'attachedTxts',
            displayName: 'TXT',
            preview: false,
            processFile: async (file) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = (event) => {
                        resolve({
                            fileName: file.name,
                            content: `TXT: ${file.name}: ${event.target.result}`
                        });
                    };
                    reader.onerror = reject;
                    reader.readAsText(file);
                });
            }
        },
        csv: {
            max: 5,
            maxSize: 30 * 1024 * 1024,
            types: ['text/csv'],
            stateKey: 'attachedCsvs',
            displayName: 'CSV',
            preview: false,
            processFile: async (file) => {
                return new Promise((resolve, reject) => {
                    Papa.parse(file, {
                        complete: (results) => {
                            const formattedData = results.data
                                .map(row => Array.isArray(row) ? row.join(', ') : Object.values(row).join(', '))
                                .join('\n');
                            resolve({
                                fileName: file.name,
                                content: `CSV: ${file.name}: ${formattedData}`
                            });
                        },
                        error: reject,
                        header: true,
                        skipEmptyLines: true
                    });
                });
            }
        },
        xlsx: {
            max: 5,
            maxSize: 30 * 1024 * 1024,
            types: [
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/vnd.ms-excel'
            ],
            stateKey: 'attachedXlsx',
            displayName: 'XLSX',
            preview: false,
            processFile: async (file) => {
                const ExcelJS = await import('exceljs');
                const workbook = new ExcelJS.Workbook();
                await workbook.xlsx.load(await file.arrayBuffer());
                
                let contentText = '';
                workbook.eachSheet((worksheet, sheetId) => {
                    contentText += `\nSheet ${sheetId}:\n`;
                    worksheet.eachRow((row, rowNumber) => {
                        const rowData = row.values
                            .slice(1)
                            .map(cell => {
                                if (cell === null || cell === undefined) return '';
                                if (cell.text) return cell.text;
                                if (cell.result) return cell.result;
                                return cell.toString();
                            })
                            .join(', ');
                        contentText += `${rowData}\n`;
                    });
                });
                return {
                    fileName: file.name,
                    content: `XLSX: ${file.name}: ${contentText}`
                };
            }
        },
        json: {
            max: 5,
            maxSize: 30 * 1024 * 1024,
            types: ['application/json'],
            stateKey: 'attachedJsons',
            displayName: 'JSON',
            preview: false,
            processFile: async (file) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = (event) => {
                        try {
                            const jsonData = JSON.parse(event.target.result);
                            const formattedJson = JSON.stringify(jsonData, null, 2);
                            resolve({
                                fileName: file.name,
                                content: `JSON: ${file.name}: ${formattedJson}`
                            });
                        } catch (error) {
                            reject(`Error parsing JSON file ${file.name}: ${error.message}`);
                        }
                    };
                    reader.onerror = reject;
                    reader.readAsText(file);
                });
            }
        }
    };

    useEffect(() => {
        if (editorViewRef.current) editorViewRef.current.focus();
    }, [editorViewRef]);

    useEffect(() => {
        // Skip check if in magic mode and first message
        if (magicMode && !firstMessageSent) {
            return;
        }
    
        const currentModel = modelInfo.find(model => model.versions.includes(selectedModel));
        const hasVision = currentModel?.vision || false;
    
        // Check current file attachments
        const hasNewImages = fileAttachments.uploadedImages?.length > 0;
        
        // Check previous messages for images
        const hasExistingImages = messages.some(message => 
            message.role === 'user' && 
            message.content.some(item => item.type === 'image_url')
        );
    
        if ((hasNewImages || hasExistingImages) && !hasVision) {
            toast.warning(
                hasExistingImages 
                    ? `Looks like there are images in this conversation. ${currentModel?.name || 'Selected model'} can't understand images. Please select a different model`
                    : `${currentModel?.name || 'Selected model'} doesn't understand images. Please remove images or switch to a vision-capable model.`,
                { autoClose: 10000 }
            );
        }
    }, [selectedModel]);

    useEffect(() => {
        const initEditor = (element) => {
            if (element && !editorViewRef.current) {
                const view = new EditorView(element, {
                    state: EditorState.create({
                        schema: mySchema,
                        // Use the current input state to initialize the editor
                        doc: mySchema.node("doc", null, [
                            mySchema.node("paragraph", null, input ? [mySchema.text(input)] : [])
                        ]),
                        plugins: [
                            history(),
                            inputRules({rules: [unorderedListRule, orderedListRule]}),
                            keymap({
                                'Shift-Enter': (state, dispatch) => {
                                    if (splitListItem(mySchema.nodes.list_item)(state, dispatch)) return true;
                                    if (dispatch) {
                                        dispatch(state.tr.replaceSelectionWith(mySchema.nodes.hard_break.create()));
                                    }
                                    return true;
                                },
                                'Enter': (state, dispatch) => {
                                    if (submitButtonRef.current && !submitButtonRef.current.disabled) {
                                        submitButtonRef.current.click();
                                    }
                                    return true;
                                },
                                'Mod-b': toggleMark(mySchema.marks.strong),
                                'Mod-i': toggleMark(mySchema.marks.em),
                                'Mod-z': undo,
                                'Mod-y': redo,
                                'Mod-Shift-z': redo,
                                'Mod-Shift-8': wrapInList(mySchema.nodes.bullet_list),
                                'Mod-Shift-9': wrapInList(mySchema.nodes.ordered_list),
                                'Tab': sinkListItem(mySchema.nodes.list_item),
                                'Shift-Tab': liftListItem(mySchema.nodes.list_item),
                            }),
                            keymap(baseKeymap),
                            new Plugin({
                                props: {
                                    decorations(state) {
                                        const doc = state.doc;
                                        if (doc.textContent.length > 0) return null;
                                        
                                        const decorations = [];
                                        decorations.push(Decoration.widget(0, () => {
                                            const placeholder = document.createElement("span");
                                            placeholder.className = "placeholder";
                                            placeholder.textContent = firstMessageSent 
                                                ? "Remember you can switch models mid-conversation"
                                                : "What's on your mind?";
                                            return placeholder;
                                        }));
                                        
                                        return DecorationSet.create(doc, decorations);
                                    },
                                    handlePaste(view, event) {
                                        const items = event.clipboardData.items;
                                        if (items && items.length > 0) {
                                            const itemsArray = Array.from(items);
                                            
                                            // Check file limits before processing
                                            const filesByType = {};
                                            itemsArray.forEach(item => {
                                                if (item.kind === 'file') {
                                                    const file = item.getAsFile();
                                                    const fileConfig = Object.values(FILE_CONFIGS).find(config => 
                                                        config.types.some(type => file.type.startsWith(type))
                                                    );
                                                    if (fileConfig) {
                                                        const stateKey = fileConfig.stateKey;
                                                        filesByType[stateKey] = (filesByType[stateKey] || 0) + 1;
                                                    }
                                                }
                                            });
    
                                            // Check if any file type exceeds the limit
                                            let exceedsLimit = false;
                                            Object.entries(filesByType).forEach(([stateKey, count]) => {
                                                const currentFiles = fileAttachments[stateKey]?.length || 0;
                                                const fileConfig = Object.values(FILE_CONFIGS).find(c => c.stateKey === stateKey);
                                                if (currentFiles + count > fileConfig.max) {
                                                    toast.warning(`Cannot attach more than ${fileConfig.max} ${fileConfig.displayName} files`);
                                                    exceedsLimit = true;
                                                }
                                            });
    
                                            if (!exceedsLimit) {
                                                handleFileInput(itemsArray);
                                            }
                                            
                                            if (itemsArray.some(item => item.kind === 'file')) {
                                                event.preventDefault();
                                                return true;
                                            }
                                        }
                                        return false;
                                    }
                                }
                            })
                        ]
                    }),
                    dispatchTransaction(transaction) {
                        let newState = view.state.apply(transaction);
                        view.updateState(newState);
                        const text = newState.doc.textContent;
                        setInput(text);
                        adjustEditorHeight();
                    },
                });
                editorViewRef.current = view;

                // Set cursor to end of input
                const lastPos = view.state.doc.resolve(view.state.doc.content.size);
                view.dispatch(view.state.tr.setSelection(TextSelection.near(lastPos)));
                view.focus();
            }
        };

        if (editorRef.current) {
            // Destroy existing editor
            if (editorViewRef.current) {
                editorViewRef.current.destroy();
                editorViewRef.current = null;
            }

            // Initialize new editor with previous content
            initEditor(editorRef.current);
        }

        return () => {
            if (editorViewRef.current) {
                editorViewRef.current.destroy();
                editorViewRef.current = null;
            }
        };
    }, [selectedModel, fileAttachments]);

    useEffect(() => {
        if (editorViewRef.current) {
            editorViewRef.current.focus();
        }
    }, [editorViewRef.current]);

    useEffect(() => {
        if (selectedTemplate && editorViewRef.current) {
            // Create a simple paste event with the template text
            const pasteEvent = new ClipboardEvent('paste', {
                clipboardData: new DataTransfer(),
                bubbles: true,
            });
            // Set the text content
            pasteEvent.clipboardData.setData('text/plain', selectedTemplate.decrypted_prompt.trim());
            
            // Clear existing content first
            const { state, dispatch } = editorViewRef.current;
            dispatch(state.tr.delete(0, state.doc.content.size));
            
            // Trigger the paste event
            editorViewRef.current.dom.dispatchEvent(pasteEvent);
            
            // Focus the editor
            editorViewRef.current.focus();
        }
        setSelectedTemplate(null);
    }, [selectedTemplate]);

    const adjustEditorHeight = () => {
        if (editorRef.current) {
            editorRef.current.style.height = 'auto';
            editorRef.current.style.height = `${editorRef.current.scrollHeight}px`;
        }
    };

    const handleFileInput = async (items) => {
        try {
            // Convert items to files array
            const files = Array.from(items)
                .map(item => item instanceof File ? item : item.getAsFile())
                .filter(Boolean);

            // Check for unsupported file types
            const unsupportedFiles = files.filter(file => {
                return !Object.values(FILE_CONFIGS).some(config => 
                    config.types.some(type => file.type.startsWith(type))
                );
            });

            if (unsupportedFiles.length > 0) {
                const fileTypes = Object.values(FILE_CONFIGS)
                    .flatMap(config => config.displayName)
                    .join(', ');
                toast.info(`Unsupported file type. Supported formats: ${fileTypes}`);
                return;
            }

            // Check model compatibility for images only if not in magic mode
            if (!magicMode) {
                const hasImages = files.some(file => file.type.startsWith('image/'));
                const currentModel = modelInfo.find(model => model.versions.includes(selectedModel));
                
                if (hasImages && !currentModel?.vision) {
                    toast.warning(`${currentModel?.name || 'Selected model'} doesn't understand images`);
                    return;
                }
            }
    
            // Pre-validate file counts
            const filesByType = {};
            files.forEach(file => {
                const fileConfig = Object.values(FILE_CONFIGS).find(config => 
                    config.types.some(type => file.type.startsWith(type))
                );
                if (fileConfig) {
                    const stateKey = fileConfig.stateKey;
                    filesByType[stateKey] = (filesByType[stateKey] || 0) + 1;
                }
            });
    
            // Check if any file type exceeds the limit
            let exceedsLimit = false;
            Object.entries(filesByType).forEach(([stateKey, count]) => {
                const currentFiles = fileAttachments[stateKey]?.length || 0;
                const fileConfig = Object.values(FILE_CONFIGS).find(c => c.stateKey === stateKey);
                if (currentFiles + count > fileConfig.max) {
                    toast.warning(`Cannot attach more than ${fileConfig.max} ${fileConfig.displayName} files`);
                    exceedsLimit = true;
                }
            });
    
            if (exceedsLimit) {
                return;
            }
    
            // Process each file
            const updatedAttachments = { ...fileAttachments };
            
            for (const file of files) {
                // Find matching file config
                const fileConfig = Object.values(FILE_CONFIGS).find(config => 
                    config.types.some(type => file.type.startsWith(type))
                );
    
                if (!fileConfig) continue;
    
                const { stateKey, maxSize, processFile } = fileConfig;
    
                // Validate file size
                if (file.size > maxSize) {
                    toast.error(`${file.name} exceeds the ${fileConfig.displayName} file size limit of ${maxSize / (1024 * 1024)}MB`);
                    continue;
                }
    
                // Process file and update state
                try {
                    const processedFile = await processFile(file);
                    setFileAttachments(prev => ({
                        ...prev,
                        [stateKey]: [...prev[stateKey], processedFile]
                    }));
                } catch (error) {
                    toast.error(`Error processing ${file.name}: ${error.message}`);
                }
            }
        } catch (error) {
            console.error('Error handling files:', error);
            toast.error('Error handling files');
        }
    };

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dragCounter.current++;
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setIsDragging(true);
        }
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dragCounter.current--;
        if (dragCounter.current === 0) {
            setIsDragging(false);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        dragCounter.current = 0;

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            handleFileInput(e.dataTransfer.files);
        }
    };

    const formatParagraph = (node) => {
        let text = '';
        node.forEach(inline => {
            if (inline.type.name === 'hard_break') {
                text += '  \n';
            } else {
                let content = inline.text;
                inline.marks.forEach(mark => {
                    if (mark.type.name === 'strong') {
                        content = `**${content}**`;
                    } else if (mark.type.name === 'em') {
                        content = `*${content}*`;
                    }
                });
                text += content;
            }
        });
        return text;
    };

    const handleSendMessage = async () => { 
        try {
            
            // Fetch the latest credit balance before proceeding
            const userInfoResponse = await fetch(`${API_URL}/user/info/${user.sub}`);

            let userCreditsBalance = 0;
            if (userInfoResponse.ok) {
                const userInfoData = await userInfoResponse.json();
                userCreditsBalance = userInfoData.userInfo.points_balance;
                setCreditsBalance(userInfoData.userInfo.points_balance);                
            } else {
                console.error('Failed to fetch updated credits balance');
            }

            let modelToUse = selectedModel;

            if (magicMode && !firstMessageSent) {
                // Check for attachments
                const hasAttachment = Object.values(fileAttachments).some(files => files.length > 0);
                
                try {
                    const response = await fetch(`${API_URL}/recommendation`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            prompt: input.trim(),
                            hasAttachment
                        })
                    });

                    if (!response.ok) {
                        throw new Error('Failed to get model recommendation');
                    }

                    const { recommendedModel } = await response.json();
                    modelToUse = recommendedModel;
                    setSelectedModel(recommendedModel);
                } catch (error) {
                    console.error('Error getting model recommendation:', error);
                    toast.warning('Could not get model recommendation. Using fallback model instead.');
                    // Continue with selected model if recommendation fails
                }
            }
   

            // Grab the cost of the selected model
            const selectedModelCost = modelInfo.find(model => model.versions.includes(modelToUse))?.cost || 0;
            
            // Check if the user has enough credits
            if (selectedModelCost > userCreditsBalance) {
                const affordableModels = modelInfo.filter(model => model.cost <= userCreditsBalance);
                const errorMessage = affordableModels.length > 0
                    ? `Looks like you don't have enough credits. Select a model with a lower cost or wait until tomorrow`
                    : "Looks like you don't have enough credits. Wait until tomorrow";
                toast.warning(errorMessage);
                return;
            }

            const doc = editorViewRef.current.state.doc;
            let text = '';

            const processNode = (node, depth = 0) => {
                if (node.type.name === 'paragraph') {
                    text += formatParagraph(node) + '\n';
                } else if (node.type.name === 'ordered_list') {
                    node.forEach(listItem => {
                        listItem.content.forEach((childNode, i) => {
                            if (i === 0) {
                                text += '   '.repeat(depth) + '1. ' + formatParagraph(childNode) + '\n';
                            } else {
                                processNode(childNode, depth + 1);
                            }
                        });
                    });
                } else if (node.type.name === 'bullet_list') {
                    node.forEach(listItem => {
                        listItem.content.forEach((childNode, i) => {
                            if (i === 0) {
                                text += '   '.repeat(depth) + '- ' + formatParagraph(childNode) + '\n';
                            } else {
                                processNode(childNode, depth + 1);
                            }
                        });
                    });
                }
            };
    
            doc.forEach(node => {
                processNode(node);
            });

            text = text.trim();
            editorViewRef.current.dispatch(editorViewRef.current.state.tr.delete(0, editorViewRef.current.state.doc.content.size));

            const newMessage = {
                role: 'user',
                content: [
                    { type: 'text', text: input.trim() },
                    ...Object.entries(fileAttachments).flatMap(([stateKey, files]) => {
                        const config = Object.values(FILE_CONFIGS).find(c => c.stateKey === stateKey);
                        if (!config || !files.length) return [];
    
                        if (stateKey === 'uploadedImages') {
                            return files.map(file => ({
                                type: 'image_url',
                                image_url: { url: file }
                            }));
                        }
    
                        // For non-image files
                        return files.map(file => ({
                            type: 'text',
                            text: file.content
                        }));
                    })
                ],
                model: modelToUse
            };

            if (!firstMessageSent) {
                setFirstMessageSent(true);
            }

            let updatedMessages = [...messages, newMessage]
            setMessages(updatedMessages);

            setFileAttachments(
                Object.fromEntries(
                    Object.values(FILE_CONFIGS).map(config => [config.stateKey, []])
                )
            );

            const currentModel = modelInfo.find(model => model.versions.includes(modelToUse));
            const hasVision = currentModel?.vision || false;

            // Strip images if model doesn't support vision
            if (!hasVision) {
                // Filter images out of the new message
                newMessage.content = newMessage.content.filter(item => item.type !== 'image_url');
    
                // Filter images out of previous user messages
                updatedMessages = updatedMessages.map(message => {
                    if (message.role === 'user') {
                        return {
                            ...message,
                            content: message.content.filter(item => item.type !== 'image_url')
                        };
                    }
                    return message;
                });
            }

            const { conversationId: newConversationId } = await askAi(
                modelToUse, 
                updatedMessages, 
                setMessages, 
                text, 
                setStreamedResponse, 
                setStreaming, 
                user, 
                conversationId,
                setConversations,
                setCreditsBalance, 
                selectedAssistant,
                magicMode,
                magicModeSetting,
                setMagicModeSetting
            );

            if (newConversationId) {
                setConversationId(newConversationId);
            }

            // Update the conversations list
            const response = await fetch(`${API_URL}/conversations/${user.sub}`);
            if (response.ok) {
                const updatedConversations = await response.json();
                setConversations(updatedConversations);
            }

        } catch (error) {
            console.error('handleSendMessage - Error sending message:', error);
            toast.error(`Seems like there is an issue on the ${modelToUse}'s side. Please try again or send a new message.`);
            setStreaming(false);
            
            // Remove the last user message from the messages array
            setMessages(prevMessages => prevMessages.slice(0, -1));
        }
    };

    return (
        <div className="flex flex-col justify-end h-full w-full px-2 sm:px-0">
            <div 
                className="relative flex items-center w-full sm:w-5/6 max-w-[1000px] mx-auto rounded-3xl bg-secondary dark:bg-secondary-dark bg-opacity-50 shadow-sm p-[2px_5px]"
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDragOver={handleDrag}
                onDrop={handleDrop}
            >
                <div
                    ref={editorRef}
                    style={{ height: 'auto', marginBottom: '2px'}}
                    className={`w-full bg-transparent border-none px-4 text-text dark:text-text-dark resize-none overflow-y-auto h-10 max-h-[60vh] leading-[2] focus:outline-none ${isDragging ? 'bg-primary bg-opacity-10' : ''}`}
                />
                {isDragging && (
                    <div className="absolute inset-0 flex items-center justify-center bg-primary bg-opacity-10 rounded-3xl pointer-events-none">
                        <p className="text-primary font-semibold">Drop files here</p>
                    </div>
                )}
                <input
                    type="file"
                    accept="image/png, image/jpeg, image/webp, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv, text/plain, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/json"
                    onChange={(e) => handleFileInput(e.target.files)}
                    style={{ display: 'none' }}
                    id="fileUpload"
                    multiple
                />
                <div className="flex h-full items-end">
                    <Tooltip content="Attach docs and images (PDF, DOCX, TXT, CSV, XLSX, JSON, PNG, JPEG, WebP)" width="w-64">
                        <label 
                            htmlFor="fileUpload"
                            className="m-[5px] p-1 ml-2 cursor-pointer flex items-center justify-center w-8 h-8 flex-shrink-0 rounded-full transition-all duration-300 ease-in-out hover:bg-hover dark:hover:bg-hover-dark"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className={`w-5 h-5 ${darkMode ? 'text-secondary' : 'text-secondary-dark'}`} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48" />
                            </svg>
                        </label>
                    </Tooltip>
                </div>

                <div className="flex h-full items-end">
                    <button 
                        id="submit-button" 
                        onClick={handleSendMessage} 
                        disabled={streaming || input.trim() === ''} 
                        className="rounded-full border-none bg-primary m-[5px] p-1 disabled:bg-border disabled:opacity-60 hover:bg-primary-hover hover:scale-102 transition-all w-8 h-8 flex-shrink-0 flex items-center justify-center"
                        ref={submitButtonRef}
                    >
                        <img src="/arrow-icon.svg" alt="Arrow Icon" className="w-5 h-5" />
                    </button>
                </div>
            </div>
            <div className="flex w-full mx-auto justify-center p-1">
                <p className="text-xs text-gray-400 block">Models can make mistakes. Check the answers</p>
            </div>
        </div>
    );
};

export default InputContainer;