import React, { useMemo, useEffect } from 'react';
import ChatMessage from './ChatMessage';
import { getModelInfo } from './ModelData';
import { toast } from 'react-toastify';

const ChatArea = ({ user, selectedModel, messages, chatEnd, streamedResponse, streaming, darkMode }) => {
  const modelInfo = useMemo(() => getModelInfo(darkMode), [darkMode]);

  const getModelDetails = (modelId) => 
    modelInfo.find(model => model.versions.includes(modelId)) || {};
  
  const initialModel = messages.length > 0 ? getModelDetails(messages[0].model) : getModelDetails(selectedModel);

  const displayMessages = [...messages];
  if (streamedResponse && (streaming || messages[messages.length - 1]?.role !== 'assistant')) {
    displayMessages.push({ role: 'assistant', content: streamedResponse, model: selectedModel });
  }

  const showBuffering = streamedResponse === '' && messages[messages.length - 1]?.role === 'user';

  useEffect(() => {
    if (messages.length >= 50 && (messages.length - 50) % 20 === 0) {
      toast.info(
        "This conversation is getting long. Consider starting a new one for better performance.", 
        {
          toastId: `long-conversation-${messages.length}`,
          autoClose: false,
        }
      );
    }
  }, [messages.length, darkMode]);

  return (
    <div className="flex flex-col justify-start items-center overflow-y-auto scroll-smooth bg-background dark:bg-background-dark">
      <div className="w-11/12 sm:w-5/6 max-w-4xl">
        {messages.length > 0 && (
          // Display which model the chat was started with
          <div className="text-center justify-center py-2 text-sm text-text dark:text-text-dark w-full sm:mx-3">
            Started chat with {initialModel.logo && <img src={initialModel.logo} alt={initialModel.name} className="inline-block h-4 w-4 pb-0.5" />} <strong>{initialModel.name}</strong>, by {initialModel.developer}
          </div>
        )}
        {displayMessages.map((message, i) => (
          <React.Fragment key={i}>
            {i > 0 && message.model && messages[i - 1].model && message.model !== messages[i - 1].model && (
              <div className={`text-center py-1 sm:py-2 sm:my-2 text-sm ${darkMode ? 'text-text-dark' : 'text-text'} border-t border-border`}>
                <p>Switched to {getModelDetails(message.model).logo && <img src={getModelDetails(message.model).logo} alt={getModelDetails(message.model).name} className="inline-block h-4 w-4 pb-0.5" />} <strong>{getModelDetails(message.model).name}</strong>, by {getModelDetails(message.model).developer}</p>
              </div>
            )}
            <ChatMessage 
              selectedModel={message.model}
              message={message} 
              user={user}
              streamedResponse={streamedResponse}
              darkMode={darkMode}
            />
          </React.Fragment>
        ))}
        {showBuffering && (
          <ChatMessage 
            selectedModel={selectedModel}
            message={{ role: 'assistant', content: '', model: selectedModel }}
            user={user}
            streamedResponse=""
            darkMode={darkMode}
            isBuffering={true}
          />
        )}
        <div ref={chatEnd}/>
      </div>
    </div>
  );
};

export default ChatArea;