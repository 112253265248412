import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import AuthenticatedApp from './components/AuthenticatedApp';
import WebsiteLayout from './components/WebsiteLayout';
import LandingPage from './components/LandingPage';
import PrivacyPolicy from './components/PrivacyPolicy';
import CookiePolicy from './components/CookiePolicy';
import TermsOfUse from './components/TermsOfUse';
import CheckoutForm from './components/CheckoutForm';
import CheckoutReturn from './components/CheckoutReturn.jsx';
import { API_URL } from './config/api';

function App() {
    const { isAuthenticated, user } = useAuth0();
    const [darkMode, setDarkMode] = useState(() => {
        const stored = localStorage.getItem('darkMode');
        return stored === null ? true : stored === 'true';
    });
    const [hasAccess, setHasAccess] = useState();
    const [selectedPlan, setSelectedPlan] = useState('plus');
    const [paymentComplete, setPaymentComplete] = useState(false);

    const toggleDarkMode = () => {
      setDarkMode(prev => !prev);
    };

    useEffect(() => {
      if (darkMode) {
          document.documentElement.classList.add('dark');
      } else {
          document.documentElement.classList.remove('dark');
      }
      localStorage.setItem('darkMode', darkMode);
    }, [darkMode]);

    useEffect(() => {
        if (isAuthenticated && user?.sub) {
            fetch(`${API_URL}/user/access/${user.sub}`)
                .then(res => res.json())
                .then(data => {
                    // Only set hasAccess if payment is not complete
                    // This prevents auto-redirect after payment
                    if (!paymentComplete) {
                        setHasAccess(data.hasAccess);
                    }
                })
                .catch(error => {
                    console.error('Error fetching user access:', error);
                });
        }
    }, [isAuthenticated, user, paymentComplete]);

    // Public routes
    if (!isAuthenticated) {
        return (
            <BrowserRouter>
                <div className="app-container">
                    <Routes>
                        <Route path="/terms" element={
                            <WebsiteLayout darkMode={darkMode} toggleDarkMode={toggleDarkMode}>
                                <TermsOfUse />
                            </WebsiteLayout>
                        } />
                        <Route path="/privacy" element={
                            <WebsiteLayout darkMode={darkMode} toggleDarkMode={toggleDarkMode}>
                                <PrivacyPolicy />
                            </WebsiteLayout>
                        } />
                        <Route path="/cookies" element={
                            <WebsiteLayout darkMode={darkMode} toggleDarkMode={toggleDarkMode}>
                                <CookiePolicy />
                            </WebsiteLayout>
                        } />
                        <Route path="/*" element={
                            <WebsiteLayout darkMode={darkMode} toggleDarkMode={toggleDarkMode}>
                                <LandingPage darkMode={darkMode} setSelectedPlan={setSelectedPlan} />
                            </WebsiteLayout>
                        } />
                    </Routes>
                    <ToastContainer
                        position="top-right"
                        theme={darkMode ? 'dark' : 'light'}
                        toastClassName={`${darkMode ? 'border border-border-dark' : ''}`}
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick={false}
                        rtl={false}
                        pauseOnFocusLoss={true}
                        draggable
                        pauseOnHover
                    />
                </div>
            </BrowserRouter>
        );
    } else {
        if (!hasAccess) {
            return (
                <BrowserRouter>
                    <div className="app-container">
                        <Routes>
                            <Route path="/return" element={<CheckoutReturn darkMode={darkMode} setHasAccess={setHasAccess} setPaymentComplete={setPaymentComplete} />} />
                            <Route path="/checkout" element={<CheckoutForm darkMode={darkMode} selectedPlan={selectedPlan} setSelectedPlan={setSelectedPlan} />} />
                            <Route path="/*" element={<Navigate to={`/checkout${window.location.search}`} />} />
                        </Routes>
                    </div>
                </BrowserRouter>
            );
        } else {
            return <AuthenticatedApp darkMode={darkMode} toggleDarkMode={toggleDarkMode} />;
        }
    }
}

export default App;