import React from 'react';
import Tooltip from './Tooltip';

const MagicMode = ({ magicMode, setMagicMode }) => {
    return (
        <div className="flex items-center justify-center mt-12 border border-dashed border-gray-200 dark:border-border-dark p-3 rounded-2xl">
            <div className="flex items-center gap-2">
                <span className="text-sm text-text dark:text-text-dark flex items-center gap-1">
                    Magic Mode
                    <Tooltip content="When enabled, Elly reads your prompt and selects which model to use (beta testing and only for English prompts🇬🇧)" width="w-72">
                        <svg className="w-4 h-4 text-gray-400 dark:text-text-dark/40" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </Tooltip>
                </span>
                <button
                    type="button"
                    role="switch"
                    aria-checked={magicMode}
                    onClick={() => setMagicMode(!magicMode)}
                    className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none ${
                        magicMode ? 'bg-primary' : 'bg-gray-200 dark:bg-gray-700'
                    }`}
                >
                    <span className="sr-only">Toggle magic mode</span>
                    <span
                        aria-hidden="true"
                        className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white dark:bg-gray-200 shadow ring-0 transition duration-200 ease-in-out ${
                            magicMode ? 'translate-x-5' : 'translate-x-0'
                        }`}
                    />
                </button>
            </div>
        </div>
    );
};

export default MagicMode;