import React, { useState, useEffect, useRef } from 'react';
import { getModelInfo } from './ModelData';

const DesignAssistantPopup = ({ onClose, onSubmit, editAssistant = null }) => {
    // State management
    const [assistantTitle, setAssistantTitle] = useState(editAssistant ? editAssistant.decrypted_title : '');
    const [assistantInstruction, setAssistantInstruction] = useState(editAssistant ? editAssistant.decrypted_instruction : '');
    const [assistantModel, setAssistantModel] = useState(editAssistant ? editAssistant.model : 'No preference');
    const titleInputRef = useRef(null);

    // Focus on title input when component mounts
    useEffect(() => {
        titleInputRef.current?.focus();
    }, []);

    const modelOptions = [
        { id: 'No preference', name: 'No preference' },
        ...getModelInfo()
            .filter(model => !model.hidden)
            .map(model => ({ id: model.id, name: model.name }))
    ];

    const handleSubmit = (e) => {
        e.preventDefault();
        if (assistantTitle && assistantInstruction) {
            onSubmit(
                assistantTitle, 
                assistantInstruction, 
                assistantModel, 
                editAssistant ? editAssistant.assistant_id : null
            );
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-10 backdrop-blur-sm p-4" onClick={onClose}>
            <div className="bg-white dark:bg-gray-800 rounded-lg p-4 sm:p-6 w-full max-w-sm sm:max-w-xl" onClick={e => e.stopPropagation()}>
                <h2 className="text-lg sm:text-xl font-bold mb-4 text-text dark:text-text-dark">
                    {editAssistant ? 'Edit your assistant' : 'Design your assistant'}
                </h2>
                <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
                    <input
                        ref={titleInputRef}
                        type="text"
                        value={assistantTitle}
                        onChange={(e) => setAssistantTitle(e.target.value)}
                        placeholder="Give your assistant a name"
                        className="w-full px-3 py-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:outline-none focus:ring-2 focus:ring-primary lg:focus:ring-0"
                    />
                    <textarea
                        value={assistantInstruction}
                        onChange={(e) => setAssistantInstruction(e.target.value)}
                        placeholder="Write your assistant's instructions"
                        className="w-full px-3 py-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:outline-none focus:ring-2 focus:ring-primary lg:focus:ring-0 h-52"
                    />

                    {/* Model selection */}
                    <div>
                        <label htmlFor="model-select" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                            Any preferred model?
                        </label>
                        <select
                            id="model-select"
                            value={assistantModel}
                            onChange={(e) => setAssistantModel(e.target.value)}
                            className="w-full px-3 py-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:outline-none focus:ring-2 focus:ring-primary lg:focus:ring-0"
                        >
                            {modelOptions.map((model) => (
                                <option key={model.id} value={model.id}>
                                    {model.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="flex flex-col sm:flex-row justify-between space-y-2 sm:space-y-0 sm:space-x-2">
                        <button
                            type="button"
                            onClick={onClose}
                            className="w-full sm:w-1/2 px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            disabled={!assistantTitle || !assistantInstruction}
                            className="w-full sm:w-1/2 px-4 py-2 bg-primary text-white rounded hover:bg-primary-hover disabled:bg-gray-300 disabled:text-gray-500 transition-colors"
                        >
                            {editAssistant ? 'Update Assistant' : 'Create Assistant'}
                        </button>
                        
                    </div>
                </form>
            </div>
        </div>
    );
};

export default DesignAssistantPopup;